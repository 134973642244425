import axios from "axios";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const adminLogin = async (password) => {
  try {
    const response = await axios({
      url: `${BASE_URL}/admin/login`,
      method: "POST",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ password }),
    });
    return response.data;
  } catch (error) {
    console.error("Admin Login Error:", error);
    throw error;
  }
};

export const forceLogoutAllUsers = async () => {
  try {
    const response = await axios({
      url: `${BASE_URL}/admin/trigger-maintenance-logout`,
      method: "POST",
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Force Logout Error:", error);
    throw error;
  }
};
