import { useState, useEffect } from "react";
import { Box, Button, TextField, CircularProgress } from "@mui/material";
import { getBooksByGoogleAPI } from "../api/bookApi";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearch } from "../context/SearchContext";

const BookSearchBar = ({
  isMarketplace,
  bookSearchResult,
  smallLabel,
  // showTotalItems
}) => {
  // const [filter, setFilter] = useState("title");
  const [searchTerm, setSearchTerm] = useState("");
  // const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { searchQuery } = useSearch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (searchQuery.trim() !== "") {
      handleSearch();
    }
  }, [searchQuery]);

  // useEffect(() => {
  //   if (searchTerm.trim() !== "") {
  //     const queryParams = new URLSearchParams();
  //     queryParams.set("search", searchTerm.trim());
  //   }
  // }, [searchTerm]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("search", searchTerm.trim());
    navigate(`?${queryParams.toString()}`); // Update URL with search param
  }, [searchTerm]);

  const handleSearch = async () => {
    // setIsLoading(true);
    // setErrorMessage("");

    const searchParams = new URLSearchParams(location.search);
    const searchQueryFromUrl = searchParams.get("search");
    try {
      const response = await getBooksByGoogleAPI(searchQueryFromUrl);
      // setSearchResults(response);
      // showTotalItems(response.data.Items);
      bookSearchResult(response.data.books);
    } catch (error) {
      console.error("Error fetching books:", error);
      setErrorMessage("An error occurred while fetching books");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchQueryChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleBookSelection = (book) => {
    // Handle book selection logic (e.g., navigate to book details page)
    navigate(`/book/${book.id}`); // Assuming you have a book details route
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  return (
    // <Card>
    <Box sx={{ p: "0px 0px 8px 0px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <TextField
              fullWidth
              variant="outlined"
              label={
                smallLabel
                  ? "By Title or Author"
                  : "Search by Book Title, Author, or ISBN"
              }
              className="without-padding"
              size="small"
              onChange={handleSearchQueryChange}
              onKeyDown={handleKeyPress} // Call handleKeyPress on key press
            />
          </Box>
          <Button size="small" variant="contained" onClick={handleSearch}>
            {isLoading ? (
              <CircularProgress size="small" color="secondary" />
            ) : (
              "Search"
            )}
          </Button>
        </Box>
        {/* <FormControl>
            <RadioGroup row value={filter} onChange={handleFilterChange}>
              <FormControlLabel
                value="title"
                control={<Radio size="small" />}
                label="Title"
              />
              <FormControlLabel
                value="author"
                control={<Radio size="small" />}
                label="Author"
              />
              <FormControlLabel
                value="ISBN"
                control={<Radio size="small" />}
                label="ISBN"
              />
            </RadioGroup>
          </FormControl> */}
      </Box>
    </Box>
    // </Card>
  );
};

export default BookSearchBar;
