import {
  TextField,
  RadioGroup,
  FormControlLabel,
  Button,
  useMediaQuery,
  Radio,
  Typography,
  Box,
  Checkbox,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import Dots from "../../../components/Dots";
import { useNavigate } from "react-router-dom"; // Hook for navigation
import { submitOnboardingDataAndRegister } from "../../../api/authApi";
import { useAuth } from "../../../context/AuthContext";
import { getUserProfileData } from "../../../api/userApi";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";

const OnBoarding6 = () => {
  const theme = useTheme();
  const [subscribe, setSubscribe] = useState(true);
  const navigate = useNavigate(); // Hook for navigation
  const { checkLogin } = useAuth();
  const { updateUserProfile } = useContext(UserContext);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");

  const handleNewsletterChange = (event) => {
    setSubscribe(event.target.checked);
  };

  useEffect(() => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    if (onBoardingData.subscribe !== undefined) {
      setSubscribe(onBoardingData.subscribe);
    }
  }, []);

  // retries is used to make sure data is fully registered in the db before fetching
  const fetchUserData = async (retries = 5, delay = 1000) => {
    for (let i = 0; i < retries; i++) {
      try {
        const result = await getUserProfileData();
        updateUserProfile(result.data);
        localStorage.setItem("userData", JSON.stringify(result.data));
        return; // Exit the function if data is successfully fetched
      } catch (error) {
        if (i < retries - 1) {
          // Wait for a while before retrying
          await new Promise((resolve) => setTimeout(resolve, delay));
        } else {
          console.error("Error fetching user data after retries:", error);
        }
      }
    }
  };

  const handleSubmit = async () => {
    const onBoardingData = JSON.parse(localStorage.getItem("onBoarding")) || {};
    const registrationToken = localStorage.getItem("registrationToken");

    // Combine data from all steps
    const registrationData = {
      ...onBoardingData,
      subscribe,
      registrationToken,
    };

    try {
      // Submit the combined data to the backend
      const result = await submitOnboardingDataAndRegister(registrationData);
      localStorage.removeItem("onBoarding");
      localStorage.removeItem("jwt");
      localStorage.removeItem("onBoardingStep1");
      localStorage.removeItem("registrationToken");
      localStorage.setItem("showMessage", "register");
      if (result.success) {
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${result.token}`;
        // Fetch user data after successful registration
        await fetchUserData();

        // Clear onboarding-related data from localStorage

        // Check login status
        await checkLogin();

        // Navigate to the home page
        navigate("/home"); // Adjust the path as needed
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed, e.g., show an error message
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: isNonMobileScreens ? "80vh" : "70vh",
        width: "full",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <img
          src="/assets/rg-new-logo-dark.webp"
          alt="ReadersGuild's logo"
          style={{ display: "flex", height: "53.91px", width: "250px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1.5,
          }}
        >
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Receive news and updates?
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={subscribe}
                onChange={handleNewsletterChange}
                name="subscribe"
                color="primary"
              />
            }
            label="Yes, join the newsletter"
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={handleSubmit}
            fullWidth
          >
            Continue
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 0.5,
              mt: 1,
            }}
          >
            <Dots navigateTo="/signup/onboarding-step1" />
            <Dots navigateTo="/signup/onboarding-step2" />
            <Dots navigateTo="/signup/onboarding-step3" />
            <Dots navigateTo="/signup/onboarding-step4" />
            <Dots navigateTo="/signup/onboarding-step5" />
            <Dots filled />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OnBoarding6;
