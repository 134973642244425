import React from "react";
import { Button, Link, Box, Card, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CardContentPadding from "../../components/CardContentPadding";

const Resources = () => {
  const theme = useTheme();
  return (
    <Card>
      <CardContentPadding>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: 2,
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "600" }}>
            Resources
          </Typography>
          <Box>
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              Check out our guides for tips on using ReadersGuild effectively!
            </Typography>
            <Button
              component={RouterLink}
              to="/guides"
              variant="outlined"
              size="medium"
              color="neutral"
              fullWidth
              sx={{ mt: 1 }}
            >
              View Guides
            </Button>
          </Box>

          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "600", mb: 1 }}>
              Need Further Assistance?
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              If you have any feedback or need help, feel free to contact me:
            </Typography>
            <Button
              variant="outlined"
              size="medium"
              target="_top"
              rel="noopener noreferrer"
              href={`mailto:fabiojonathanarifin@gmail.com`}
              color="neutral"
              fullWidth
              sx={{ mb: 1 }}
            >
              Email Me
            </Button>
            <Typography variant="body2" sx={{ fontWeight: 400 }}>
              Or reach out via{" "}
              <Link
                href="https://www.instagram.com/_fabiojonathan/"
                target="_blank"
                rel="noopener"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "inherit",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Instagram
              </Link>{" "}
              or{" "}
              <Link
                href="https://x.com/FabioJonathanA"
                target="_blank"
                rel="noopener"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "inherit",
                  "&:hover": {
                    color: theme.palette.primary.main,
                  },
                }}
              >
                Twitter
              </Link>
            </Typography>
          </Box>
        </Box>
      </CardContentPadding>
    </Card>
  );
};

export default Resources;
