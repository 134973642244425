import { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import DisplayRating from "./DisplayRating";
import {
  Box,
  Button,
  Stack,
  useTheme,
  Dialog,
  DialogTitle,
  List,
  Select,
  ListItem,
  IconButton,
  ListItemButton,
  ListItemText,
  TextField,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addBookToUserLibrary,
  postGoogleBookToDBAndUser,
} from "../api/userApi";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  removeBookFromUserCollection,
  updateBookMetadata,
} from "../api/bookApi";
import { UserContext } from "../context/UserContext";
import { useLoading } from "../context/LoadingContext";
import { addBookToTBRCollection } from "../api/tbrCollectionApi";
import EditBookDataDialog from "../components/EditBookDataDialog";
import { updateBookStatusInUserLibrary } from "../api/userApi";
import CloseIcon from "@mui/icons-material/Close";

const BookSettingDialog = ({
  onUpdateUserBooks,
  open,
  onClose,
  selectedValue,
  userBookData,
  bookStatus,

  bookId,
  onStatusChange,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const [bookData, setBookData] = useState(userBookData);
  const { updateUserProfile } = useContext(UserContext);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [status, setStatus] = useState(userBookData.status || "tbr_unbought"); // Add state for status
  const { isLoading, setIsLoading } = useLoading();

  useEffect(() => {
    setStatus(bookStatus);
    // setBookData(userBookData);
  }, [userBookData]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const handleBookClick = (e) => {
    navigate(`/library/session/` + bookId);
    onClose();
  };

  const handleRemoveBookClick = async (e) => {
    const result = await removeBookFromUserCollection(bookId);
    if (result.success === true) {
      onUpdateUserBooks(bookId);
      updateUserProfile(result.user);
      localStorage.setItem("userData", JSON.stringify(result.user));
    }
    onClose();
  };

  const defaultTbrStatus = "pre-ordered";
  const handleSaveBookToTBR = async () => {
    try {
      const result = await addBookToTBRCollection(
        year,
        bookId,
        defaultTbrStatus
      );
      if (result.success === true) {
      }
      onClose();
    } catch (error) {
      console.error("Error adding book to TBR:", error);
    }
  };

  const handleStatusChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    try {
      const result = await updateBookStatusInUserLibrary(bookId, newStatus);
      if (result.success) {
        updateUserProfile(result.user);
        localStorage.setItem("userData", JSON.stringify(result.user));

        onStatusChange(bookId, newStatus);
      } else {
        console.error("Failed to update book status:", result.message);
      }
    } catch (error) {
      console.error("Error updating book status:", error);
    }
  };

  const handleOpenEditDialog = () => {
    setIsEditDialogOpen(true);
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleSaveEditedBook = async (editedBook) => {
    setIsLoading(true);
    try {
      const result = await updateBookMetadata(bookId, editedBook);
      if (result.success) {
        onUpdateUserBooks(result.book);
        onClose();
      } else {
        throw new Error(result.message || "Failed to update book data");
      }
    } catch (error) {
      console.error("Failed to update book data", error);
    } finally {
      setIsEditDialogOpen(false);
    }
  };

  return (
    <>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          color="white"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "20px",
            backgroundColor: theme.palette.primary.main,
          }}
        >
          <Typography variant="h6" sx={{ fontSize: "700" }}>
            Book Settings
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon
              color="white"
              sx={{ color: "white", fontColor: "white" }}
            />
          </IconButton>
        </DialogTitle>

        <List sx={{ pt: 0 }}>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton
              autoFocus
              sx={{ p: "16px" }}
              onClick={() => handleBookClick()}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", fontWeight: "600" }}
                primary="Open Book Journal"
              />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters sx={{ p: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                p: "16px",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "600", mb: 0.5 }}>
                Select Subcategory:
              </Typography>
              <Select
                value={status}
                onChange={handleStatusChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="tbr_unbought">To Be Read (Not owned)</MenuItem>
                <MenuItem value="tbr_owned">To Be Read (Owned)</MenuItem>
                <MenuItem value="in_progress">Currently Reading</MenuItem>
                <MenuItem value="finished">Finished Reading</MenuItem>
              </Select>
            </Box>
          </ListItem>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton
              autoFocus
              sx={{ p: "16px" }}
              onClick={handleOpenEditDialog}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", fontWeight: "600" }}
                primary="Edit Book Data"
              />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters sx={{ p: 0 }}>
            <ListItemButton
              autoFocus
              sx={{
                p: "16px",
                "&:hover": {
                  backgroundColor: "rgba(213, 64, 65, 0.2)",
                },
              }}
              onClick={() => handleRemoveBookClick()}
            >
              <ListItemText
                primaryTypographyProps={{ fontSize: "16px", fontWeight: "600" }}
                primary="Remove Book and Book Data From Library"
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      <EditBookDataDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        book={userBookData}
        onSave={handleSaveEditedBook}
      />
    </>
  );
};

const BookCardWidget = ({
  isMarketplace = false,
  bookProvider,
  bookId,
  bookOwned,
  googleBook,
  onBookUpdate,
  userBookData,
  bookStatus,
  onUpdateUserBooksParent,
  noShadow,
  onStatusChange,
  noBorderCurve,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [book, setBook] = useState({});
  const [userBook, setUserBook] = useState({});
  const [removedBookId, setRemovedBookId] = useState("");
  const { updateUserProfile } = useContext(UserContext);
  const { setIsLoading } = useLoading();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  useEffect(() => {
    setBook(googleBook);
  }, [googleBook]);

  useEffect(() => {
    setUserBook(userBookData);
  }, [userBookData]);

  const handleOpenEditDialog = (e) => {
    e.preventDefault();
    if (book && Object.keys(book).length > 0) {
      setIsEditDialogOpen(true);
    } else {
      console.error("Book data is not available");
    }
  };

  const handleCloseEditDialog = () => {
    setIsEditDialogOpen(false);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // const handleUserBooksChange = (removedIdValue) => {
  //   setRemovedBookId(removedIdValue);
  // };
  const handleSaveEditedBook = async (editedBook) => {
    setIsLoading(true);
    try {
      let result;
      if (bookProvider === "google") {
        result = await postGoogleBookToDBAndUser(editedBook);
      } else {
        result = await addBookToUserLibrary(editedBook._id, {
          title: editedBook.title,
          authors: editedBook.authors,
          pages: editedBook.pages,
          genre: editedBook.genre,
          description: editedBook.description,
        });
      }

      if (!result.success) {
        console.error(result.message);
      } else {
        updateUserProfile(result.user);
        localStorage.setItem("userData", JSON.stringify(result.user));

        const newBookData = {
          _id: result.savedBook?._id || editedBook._id,
          id: result.savedBook?.id || editedBook.id,
          title: result.savedBook?.title || editedBook.title,
          authors: result.savedBook?.authors || editedBook.authors,
          pages: result.savedBook?.pages || editedBook.pages,
          genre: result.savedBook?.genre || editedBook.genre,
          description: result.savedBook?.description || editedBook.description,
          cover: result.savedBook?.cover || editedBook.cover,
        };

        if (onBookUpdate) {
          onBookUpdate(newBookData);
        }

        handleCloseEditDialog();

        // Navigate to the library page and force a reload
        navigate("/library", { replace: true });
        window.location.reload();
      }
    } catch (error) {
      console.error("Error saving edited book:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // can use When book db is working
  // const handleBookClick = (e) => {
  //   e.preventDefault();
  //   isMarketplace
  //     ? navigate(`/marketplace/` + bookId)
  //     : navigate(`/library/session/` + bookId);
  // };
  const handleBookClick = (e) => {
    e.preventDefault();
    navigate(`/library/session/` + bookId);
  };

  const defaultCover = "/assets/book-sample.jpg";

  return (
    <>
      {isNonMobileScreens ? (
        <Box sx={{ width: "140px" }}>
          <Box
            sx={{
              boxShadow: noShadow ? 0 : 3,
              overflow: "hidden",
              height: "198px",
              position: "relative",
              borderRadius: noBorderCurve ? "0%" : "6%",
            }}
          >
            {/* When db is built, fix this */}
            {!isMarketplace && (
              <Button
                size="small"
                variant="contained"
                onClick={handleOpenDialog}
                sx={{
                  position: "absolute",
                  right: "8px",
                  top: "8px",
                  maxWidth: "28px",
                  maxHeight: "28px",
                  minWidth: "28px",
                  minHeight: "28px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.6)", // color: "whitesmoke",
                }}
              >
                <MoreVertIcon />
              </Button>
            )}
            {isMarketplace ? (
              <img
                src={
                  userBook?.cover?.large ||
                  userBook?.cover?.medium ||
                  userBook?.cover?.small ||
                  book?.cover?.large ||
                  book?.cover?.medium ||
                  book?.cover?.small ||
                  defaultCover
                }
                alt={`Book Cover for ${
                  userBook?.title || book?.title || "Book Title"
                }`}
                style={{
                  display: "flex",
                  height: "auto",
                  width: "140px",
                  // cursor: "pointer",
                  borderRadius: noBorderCurve ? "0%" : "6%",
                }}
                // onClick={() => navigate(`/marketplace/${book.bookId}`)}
              />
            ) : (
              <img
                src={
                  userBook?.cover?.large ||
                  userBook?.cover?.medium ||
                  userBook?.cover?.small ||
                  book?.cover?.large ||
                  book?.cover?.medium ||
                  book?.cover?.small ||
                  defaultCover
                }
                alt={`Book Cover for ${
                  userBook?.title || book?.title || "Book Title"
                }`}
                style={{
                  display: "flex",
                  height: "auto",
                  width: "140px",
                  cursor: "pointer",
                  borderRadius: noBorderCurve ? "0%" : "6%",
                }}
                onClick={(e) => handleBookClick(e)}
              />
            )}
          </Box>

          {/* Only show card content on hover? animate to the right */}
          {isMarketplace && (
            <>
              <Stack gap={0.5} sx={{ mt: 1 }}>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      //2 lines, after two lines "..."
                      width: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                      fontWeight: "bold",
                    }}
                  >
                    {book.title ||
                      `The Psychology of Money cmon now nigga man afwe aewf`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.neutral.medium,
                      width: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                    }}
                  >{`By ${
                    book.authors && book.authors.length > 0
                      ? book.authors.join(",  ")
                      : "Morgan Housel"
                  }`}</Typography>
                </Box>
                {/* after amazon associate, start showing price */}
                {/* <Box>
            <Typography variant="subtitle1">$14.99</Typography>
          </Box> */}
                {book.genre && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.neutral.medium,

                        width: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                        fontStyle: "italic",
                        fontWeight: "600",
                        color: theme.palette.neutral.medium,
                      }}
                    >
                      {book.genre.join(", ")}
                    </Typography>
                  </Box>
                )}
                {book.averageRating && (
                  <Box>
                    <DisplayRating ratingValue={book.averageRating || 2.3} />
                  </Box>
                )}
                {book.ratingCount && (
                  <Box>
                    <Typography>
                      {"("} {book.ratingCount || "16,923"} {")"}
                    </Typography>
                  </Box>
                )}
                {/* <Typography>The Psychology of Money</Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {/* after amazon associate, start showing buy */}
                  {/* <Button size="small" color="primary" variant="outlined">
              Buy
            </Button> */}
                  {book.bookOwned ? (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled
                    >
                      Book Owned
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      onClick={handleOpenEditDialog}
                    >
                      Move to Library
                    </Button>
                  )}
                </Box>
              </Stack>
            </>
          )}
          <BookSettingDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            bookId={bookId}
            onUpdateUserBooks={onUpdateUserBooksParent}
            userBookData={userBookData || []}
            bookStatus={bookStatus}
            onStatusChange={onStatusChange}
          />
        </Box>
      ) : (
        <Box sx={{ width: "96px" }}>
          <Box
            sx={{
              boxShadow: noShadow ? 0 : 3,
              overflow: "hidden",
              width: "100%",
              height: "140px",
              position: "relative",
              borderRadius: noBorderCurve ? "0%" : "6%",
            }}
          >
            {/* When db is built, fix this */}
            {!isMarketplace && (
              <Button
                size="small"
                variant="contained"
                onClick={handleOpenDialog}
                sx={{
                  position: "absolute",
                  right: "8px",
                  top: "8px",
                  maxWidth: "28px",
                  maxHeight: "28px",
                  minWidth: "28px",
                  minHeight: "28px",
                  borderRadius: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.6)", // color: "whitesmoke",
                }}
              >
                <MoreVertIcon />
              </Button>
            )}
            {isMarketplace ? (
              <img
                src={
                  userBook?.cover?.large ||
                  userBook?.cover?.medium ||
                  userBook?.cover?.small ||
                  book?.cover?.large ||
                  book?.cover?.medium ||
                  book?.cover?.small ||
                  defaultCover
                }
                alt={`Book Cover for ${
                  userBook?.title || book?.title || "Book Title"
                }`}
                style={{
                  display: "flex",
                  height: "auto",
                  width: "96px",
                  cursor: "pointer",
                  borderRadius: noBorderCurve ? "0%" : "6%",
                }}
              />
            ) : (
              <img
                src={
                  userBook?.cover?.large ||
                  userBook?.cover?.medium ||
                  userBook?.cover?.small ||
                  book?.cover?.large ||
                  book?.cover?.medium ||
                  book?.cover?.small ||
                  defaultCover
                }
                alt={`Book Cover for ${
                  userBook?.title || book?.title || "Book Title"
                }`}
                style={{
                  display: "flex",
                  height: "auto",
                  width: "96px",
                  cursor: "pointer",
                  borderRadius: noBorderCurve ? "0%" : "6%",
                }}
                onClick={(e) => handleBookClick(e)}
              />
            )}
          </Box>

          {/* Only show card content on hover? animate to the right */}
          {isMarketplace && (
            <>
              <Stack gap={0.5} sx={{ mt: 1 }}>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      //2 lines, after two lines "..."
                      width: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                      fontWeight: "bold",
                    }}
                  >
                    {book.title ||
                      `The Psychology of Money cmon now nigga man afwe aewf`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.palette.neutral.medium,

                      width: "100%",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      WebkitLineClamp: 2,
                    }}
                  >{`By ${
                    book.authors && book.authors.length > 0
                      ? book.authors.join(",  ")
                      : "Morgan Housel"
                  }`}</Typography>
                </Box>
                {/* after amazon associate, start showing price */}
                {/* <Box>
              <Typography variant="subtitle1">$14.99</Typography>
            </Box> */}
                {book.genre && (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        color: theme.palette.neutral.medium,

                        width: "100%",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                        fontStyle: "italic",
                        fontWeight: "600",
                        color: theme.palette.neutral.medium,
                      }}
                    >
                      {book.genre.join(", ")}
                    </Typography>
                  </Box>
                )}
                {book.averageRating && (
                  <Box>
                    <DisplayRating ratingValue={book.averageRating || 2.3} />
                  </Box>
                )}
                {book.ratingCount && (
                  <Box>
                    <Typography>
                      {"("} {book.ratingCount || "16,923"} {")"}
                    </Typography>
                  </Box>
                )}
                {/* <Typography>The Psychology of Money</Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  {/* after amazon associate, start showing buy */}
                  {/* <Button size="small" color="primary" variant="outlined">
                Buy
              </Button> */}
                  {book.bookOwned ? (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled
                    >
                      Book Owned
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="secondary"
                      variant="outlined"
                      onClick={handleOpenEditDialog}
                    >
                      Move to Library
                    </Button>
                  )}
                </Box>
              </Stack>
            </>
          )}
          <BookSettingDialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            bookId={bookId}
            onUpdateUserBooks={onUpdateUserBooksParent}
            userBookData={userBookData || []}
            bookStatus={bookStatus}
            onStatusChange={onStatusChange}
          />
        </Box>
      )}
      <EditBookDataDialog
        open={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        book={book || {}} // Provide a default empty object if book is undefined
        onSave={handleSaveEditedBook}
      />
    </>
  );
};

export default BookCardWidget;
