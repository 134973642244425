import React, { useState } from "react";
import { Box, Card, Typography, useTheme, Button, Chip } from "@mui/material";
import CardContentPadding from "../../components/CardContentPadding";
import FlexBetween from "../../components/FlexBetween";
import parse from "html-react-parser";
import DialogEditNote from "../../components/DialogEditNote";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { removeReadingLogById } from "../../api/logApi";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DialogSeeStats from "../../components/DialogSeeStats";

const NoteWidget = ({
  bookTitle,
  bookAuthors,
  roomTag,
  privateSwitch,
  logDate,
  notes,
  logId,
  isDiscussion,
  onUpdateNote,
  onUpdateRemoveLog,
  lastPageRead,
  startPageRead,
  amountPageRead,
  bookTotalPage,
}) => {
  const theme = useTheme();
  const [isSeeStatsOpen, setIsSeeStatsOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentNotes, setCurrentNotes] = useState(notes || "");

  const handleEditNote = async (editedNote, privateSwitch) => {
    try {
      await onUpdateNote(logId, editedNote, privateSwitch);
      setCurrentNotes(editedNote);
      setIsEditDialogOpen(false);
    } catch (error) {
      console.error("Error updating reading log note:", error);
    }
  };

  const handleRemoveLog = async () => {
    try {
      await removeReadingLogById(logId);
      onUpdateRemoveLog(logId);
    } catch (error) {
      console.error("Error removing reading log:", error);
    }
  };

  return (
    <Card elevation={3}>
      <CardContentPadding>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <FlexBetween>
            <Box>
              <Typography variant="h6" fontWeight={700} color="neutral">
                {bookTitle}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                by{" "}
                {Array.isArray(bookAuthors)
                  ? bookAuthors.join(", ")
                  : bookAuthors}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{ display: "flex", flexDirection: "row-reverse", gap: 1 }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.medium,
                    fontWeight: 500,
                  }}
                  gutterBottom
                >
                  {logDate || "14 Nov 2023"}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.medium,
                    fontWeight: 500,
                  }}
                  gutterBottom
                >
                  •
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.palette.neutral.medium,
                    fontWeight: 500,
                  }}
                  gutterBottom
                >
                  Pg. Page {lastPageRead}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  startIcon={<VisibilityIcon />}
                  onClick={() => setIsSeeStatsOpen(true)}
                  color="primary"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  See Stats
                </Button>
              </Box>
            </Box>
          </FlexBetween>

          <Box
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: 1,
              p: 2,
              position: "relative",
            }}
          >
            {currentNotes ? (
              <Typography
                variant="body1"
                sx={{
                  "& p": { marginBottom: "0.5em" },
                  "& ul, & ol": { paddingLeft: "1.5em", marginBottom: "0.5em" },
                  "& li": { marginBottom: "0.25em" },
                  "& a": {
                    color: theme.palette.primary.main,
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  },
                }}
              >
                {parse(currentNotes)}
              </Typography>
            ) : (
              <Typography variant="body1" color="text.secondary">
                No notes available.
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                justifyContent: "flex-end",
                marginTop: 2,
              }}
            >
              <Button
                startIcon={<EditIcon />}
                onClick={() => setIsEditDialogOpen(true)}
                color="primary"
                size="small"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                {currentNotes ? "Edit" : "Add Note"}
              </Button>
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleRemoveLog}
                color="error"
                size="small"
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  "&:hover": {
                    backgroundColor: theme.palette.action.hover,
                  },
                }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContentPadding>
      <DialogEditNote
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onSave={handleEditNote}
        switchData={privateSwitch}
        initialNote={currentNotes}
        bookTitle={bookTitle}
        logDate={logDate}
        logId={logId}
      />
      <DialogSeeStats
        open={isSeeStatsOpen}
        onClose={() => setIsSeeStatsOpen(false)}
        logDate={logDate}
        privateSwitch={privateSwitch}
        lastPageRead={lastPageRead}
        startPageRead={startPageRead}
        amountPageRead={amountPageRead}
        bookTotalPage={bookTotalPage}
        isDiscussion={isDiscussion}
        roomTag={roomTag}
      />
    </Card>
  );
};

export default NoteWidget;
