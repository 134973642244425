import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import ContentGrid from "../../components/ContentGrid.jsx";
import NoteWidget from "../widgets/NoteWidget.jsx";
import PageFrame from "../../components/PageFrame.jsx";
import RightFriendActivityWidget from "../../layouts/rightFriendActivityWidget.jsx/index.jsx";
import { getUserReadingLogs } from "../../api/userApi.js";
import dayjs from "dayjs";
import { useSnackbar } from "../../context/SnackbarContext.js";
import { updateReadingLog } from "../../api/logApi.js";

const UserNotes = () => {
  const [readingLogs, setReadingLogs] = useState([]);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const snackbar = useSnackbar();

  const handleLogRemoval = (logId) => {
    try {
      const previousReadingLog = readingLogs;
      const result = previousReadingLog.filter((log) => log._id !== logId);
      setReadingLogs(result);
    } catch (error) {
      console.error("Error removing reading log: ", error);
    }
  };

  useEffect(() => {
    fetchUserReadingLogs();
  }, []);

  const fetchUserReadingLogs = async () => {
    try {
      const result = await getUserReadingLogs();
      const resultArrayReverse = result.data.slice().reverse();
      setReadingLogs(resultArrayReverse);
    } catch (error) {
      console.error("Error fetching readingLogs: ", error);
    }
  };

  const handleUpdateNote = async (logId, updatedNote, updatedPrivateSwitch) => {
    try {
      await updateReadingLog(logId, updatedNote, updatedPrivateSwitch);
      setReadingLogs((prevLogs) =>
        prevLogs.map((log) =>
          log._id === logId
            ? {
                ...log,
                readingNotes: updatedNote,
                privateSwitch: updatedPrivateSwitch,
              }
            : log
        )
      );
      snackbar("Note updated successfully");
    } catch (error) {
      console.error("Error updating reading log note:", error);
      snackbar("Failed to update note", "error");
    }
  };

  const hasValidNotes = (notes) => {
    if (!notes) return false; // Check if notes are undefined or null
    // Ensure that notes is treated as a string, even if it's empty
    const cleanedNotes = (notes || "").replace(/<[^>]*>/g, "").trim();
    return cleanedNotes.length > 0;
  };
  return (
    <>
      {isNonMobileScreens ? (
        <PageFrame title="Note History">
          <ContentGrid sx={{ gap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                gridColumn: "span 3",
                mt: 1.5,
              }}
            >
              {readingLogs.map((readingLog) =>
                hasValidNotes(readingLog.readingNotes) ? (
                  <NoteWidget
                    key={readingLog._id}
                    bookTitle={readingLog.book.title}
                    bookAuthors={readingLog.book.authors}
                    privateSwitch={readingLog.privateSwitch}
                    lastPageRead={readingLog.lastPageRead}
                    startPageRead={readingLog.startPageRead}
                    amountPageRead={readingLog.amountPageRead}
                    bookTotalPage={readingLog.book.pages}
                    notes={readingLog.readingNotes || ""}
                    logId={readingLog._id}
                    roomTag={
                      readingLog.room ? `#${readingLog.room.roomTag}` : null
                    }
                    isDiscussion={readingLog.isDiscussion}
                    onUpdateRemoveLog={handleLogRemoval}
                    logDate={dayjs(readingLog.postDate).format("MMM D, YYYY")}
                    onUpdateNote={handleUpdateNote}
                  />
                ) : null
              )}
            </Box>
            <RightFriendActivityWidget />
          </ContentGrid>
        </PageFrame>
      ) : (
        <PageFrame title="Note History">
          <Box sx={{ gap: 4 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1.5,
                gridColumn: "span 3",
                mt: 1.5,
              }}
            >
              {readingLogs.map((readingLog) =>
                hasValidNotes(readingLog.readingNotes) ? (
                  <NoteWidget
                    key={readingLog._id}
                    bookTitle={readingLog.book.title}
                    bookAuthors={readingLog.book.authors}
                    privateSwitch={readingLog.privateSwitch}
                    lastPageRead={readingLog.lastPageRead}
                    startPageRead={readingLog.startPageRead}
                    amountPageRead={readingLog.amountPageRead}
                    bookTotalPage={readingLog.book.pages}
                    notes={readingLog.readingNotes || ""}
                    logId={readingLog._id}
                    roomTag={
                      readingLog.room ? `#${readingLog.room.roomTag}` : null
                    }
                    isDiscussion={readingLog.isDiscussion}
                    onUpdateRemoveLog={handleLogRemoval}
                    logDate={dayjs(readingLog.postDate).format("MMM D, YYYY")}
                    onUpdateNote={handleUpdateNote}
                  />
                ) : null
              )}
            </Box>
            {/* <RightFriendActivityWidget /> */}
          </Box>
        </PageFrame>
      )}
    </>
  );
};

export default UserNotes;
