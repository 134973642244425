import React, { useContext, useState, useEffect } from "react";
import ContentGrid from "../../components/ContentGrid";
import LeftProfile from "../../layouts/leftProfile";
import {
  Avatar,
  Box,
  Button,
  Card,
  FormControlLabel,
  FormControl,
  Snackbar,
  Alert,
  Chip,
  CircularProgress,
  Switch,
  CardMedia,
  IconButton,
  Divider,
  MenuItem,
  Select,
  InputLabel,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  Backdrop,
  Paper,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LinearProgressBar from "../../components/LinearProgressBar";
import BookTag from "../../components/BookTag";
import { UserContext } from "../../context/UserContext";
import BookSearchCard from "../../components/BookSearchCard";
import EditBookDataDialog from "../../components/EditBookDataDialog";
import PostDiscussionDialog from "./PostDiscussionDialog";
import {
  addBookToUserLibrary,
  postGoogleBookToDBAndUser,
} from "../../api/userApi";
import parse from "html-react-parser";
import {
  createNewRoom,
  getUsersRooms,
  addRoomDiscussion,
  removeMemberFromRoom,
  updateRoomBook,
  joinRoom,
  removeRoom,
  editRoomDiscussion,
  removeDiscussion,
} from "../../api/roomApi";
import JoinRoomDialog from "./JoinRoomDialog";
import { formatDistanceToNow } from "date-fns";
import CardContentDoublePadding from "../../components/CardContentDoublePadding";
import RoomStatistics from "./RoomStatistics";
import FlexBetween from "../../components/FlexBetween";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import DialogEditNote from "../../components/DialogEditNote";
import DiscussionCard from "./DiscussionCard";
import { likeDiscussion } from "../../api/roomApi";

const Rooms = () => {
  const theme = useTheme();
  const defaultCover = "/assets/book-sample.jpg";
  const [open, setOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRoom, setSelectedRoom] = useState("");
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const [isPublic, setIsPublic] = useState(true);
  const [roomPassword, setRoomPassword] = useState("");
  const [maxMembers, setMaxMembers] = useState(10);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPostDialogOpen, setIsPostDialogOpen] = useState(false);
  const [isJoinRoomDialogOpen, setIsJoinRoomDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const [isRemoveDiscussionDialogOpen, setIsRemoveDiscussionDialogOpen] =
    useState(false);
  const [discussionToRemove, setDiscussionToRemove] = useState(null);
  const [selectedBookId, setSelectedBookId] = useState("");
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });
  const [dialogError, setDialogError] = useState("");
  const [isRemoveMemberDialogOpen, setIsRemoveMemberDialogOpen] =
    useState(false);
  const [memberToRemove, setMemberToRemove] = useState(null);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [hasRooms, setHasRooms] = useState(false);
  const [editingDiscussion, setEditingDiscussion] = useState(null);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const data = await getUsersRooms();
        setRooms(data.rooms);
        if (data.rooms.length > 0) {
          setSelectedRoom(data.rooms[0]._id); // Set the first room as default
          setHasRooms(true);
          setOpenBackdrop(false);
        } else {
          setSelectedRoom(""); // Ensure it's an empty string if no rooms
          setHasRooms(false);
          setOpenBackdrop(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, []);

  const handleRemoveMember = (member) => {
    setMemberToRemove(member);
    setIsRemoveMemberDialogOpen(true);
  };

  const handleEditDiscussion = async (updatedNote) => {
    try {
      const result = await editRoomDiscussion(
        selectedRoom,
        editingDiscussion._id,
        updatedNote
      );
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: room.discussions.map((d) =>
                    d._id === editingDiscussion._id
                      ? { ...d, readingNotes: updatedNote }
                      : d
                  ),
                }
              : room
          )
        );
        setEditingDiscussion(null);
        setSnackbar({
          open: true,
          message: "Discussion updated successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to update discussion");
      }
    } catch (error) {
      console.error("Failed to update discussion", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to update discussion",
        severity: "error",
      });
    }
  };

  const confirmRemoveMember = async () => {
    if (!memberToRemove) return;

    try {
      const result = await removeMemberFromRoom(
        selectedRoom,
        memberToRemove._id
      );
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  members: room.members.filter(
                    (m) => m._id !== memberToRemove._id
                  ),
                }
              : room
          )
        );
        setSnackbar({
          open: true,
          message: "Member removed successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to remove member");
      }
    } catch (error) {
      console.error("Failed to remove member", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to remove member",
        severity: "error",
      });
    } finally {
      setIsRemoveMemberDialogOpen(false);
      setMemberToRemove(null);
    }
  };

  const handleRemoveRoom = async () => {
    if (!isRoomCreator) {
      setSnackbar({
        open: true,
        message: "Only the room creator can remove the room",
        severity: "error",
      });
      return;
    }

    setIsRemoveDialogOpen(true);
  };

  const handleRemoveDiscussion = (discussion) => {
    setDiscussionToRemove(discussion);
    setIsRemoveDiscussionDialogOpen(true);
  };

  const confirmRemoveDiscussion = async () => {
    if (!discussionToRemove) return;

    try {
      const result = await removeDiscussion(
        selectedRoom,
        discussionToRemove._id
      );
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: room.discussions.filter(
                    (d) => d._id !== discussionToRemove._id
                  ),
                }
              : room
          )
        );
        setSnackbar({
          open: true,
          message: "Discussion removed successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to remove discussion");
      }
    } catch (error) {
      console.error("Failed to remove discussion", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to remove discussion",
        severity: "error",
      });
    } finally {
      setIsRemoveDiscussionDialogOpen(false);
      setDiscussionToRemove(null);
    }
  };

  const confirmRemoveRoom = async () => {
    try {
      const result = await removeRoom(selectedRoom);
      if (result.success) {
        setRooms((prevRooms) =>
          prevRooms.filter((room) => room._id !== selectedRoom)
        );
        setSelectedRoom("");
        setSnackbar({
          open: true,
          message: "Room removed successfully",
          severity: "success",
        });
      } else {
        throw new Error(result.message || "Failed to remove room");
      }
    } catch (error) {
      console.error("Failed to remove room", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to remove room",
        severity: "error",
      });
    } finally {
      setIsRemoveDialogOpen(false);
    }
  };

  const handleJoinRoom = async (roomTag, roomPassword) => {
    try {
      const result = await joinRoom(roomTag, roomPassword);
      if (result.success) {
        // Update the rooms state or refetch rooms
        setRooms((prevRooms) => [...prevRooms, result.room]);
        setSnackbar({
          open: true,
          message: "Successfully joined room!",
          severity: "success",
        });
        setOpenBackdrop(false);
        setHasRooms(true);
      } else {
        throw new Error(result.message || "Failed to join room");
      }
    } catch (error) {
      console.error("Failed to join room", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to join room",
        severity: "error",
      });
    }
  };

  const getCurrentUserLastPageRead = () => {
    const userProgress = currentRoom?.memberProgress?.find(
      (progress) => progress.user._id === userProfile._id
    );
    return userProgress?.lastPageRead || 0;
  };

  const handleBookSelect = (book) => {
    setSelectedBook(book);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogError(""); // Clear any existing errors
  };

  const handlePostDiscussion = async (discussionData) => {
    // Create a temporary discussion object
    const tempDiscussion = {
      _id: Date.now().toString(), // Temporary ID
      user: {
        _id: userProfile._id,
        name: userProfile.name,
        avatar: userProfile.avatar || "",
      },
      book: currentRoom.currentBook._id,
      readingNotes: discussionData.readingNotes,
      lastPageRead: discussionData.lastPageRead,
      startPageRead: discussionData.startPageRead,
      amountPageRead:
        discussionData.lastPageRead - discussionData.startPageRead,
      privateSwitch: discussionData.privateSwitch,
      postDate: new Date().toISOString(),
    };

    // Optimistically update the UI
    setRooms((prevRooms) =>
      prevRooms.map((room) =>
        room._id === selectedRoom
          ? {
              ...room,
              discussions: [tempDiscussion, ...(room.discussions || [])],
            }
          : room
      )
    );

    try {
      // Send the request to the server
      const result = await addRoomDiscussion(selectedRoom, discussionData);

      if (result.success) {
        // Update with the actual server response
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: [
                    result.readingLog,
                    ...room.discussions.filter(
                      (d) => d._id !== tempDiscussion._id
                    ),
                  ],
                }
              : room
          )
        );
        setIsPostDialogOpen(false);
      } else {
        throw new Error(result.message || "Failed to post discussion");
      }
    } catch (error) {
      // Revert the optimistic update
      setRooms((prevRooms) =>
        prevRooms.map((room) =>
          room._id === selectedRoom
            ? {
                ...room,
                discussions: room.discussions.filter(
                  (d) => d._id !== tempDiscussion._id
                ),
              }
            : room
        )
      );
      // Show error message
      setSnackbar({
        open: true,
        message: error.message || "Failed to post discussion",
        severity: "error",
      });
    }
  };

  // const handleBookSelection = async (book) => {
  //   try {
  //     //   await updateRoomBook(selectedRoom, book.id);
  //     setRooms((prevRooms) =>
  //       prevRooms.map((room) =>
  //         room.id === selectedRoom ? { ...room, currentBook: book } : room
  //       )
  //     );
  //   } catch (error) {
  //     console.error("Failed to update room book", error);
  //     setError("Failed to update room book");
  //   }
  // };

  const maxMemberOptions = [5, 10, 15, 20, 25, 30];

  const handleRoomChange = (event) => {
    setSelectedRoom(event.target.value);
  };

  const getCurrentRoomData = () => {
    return rooms.find((room) => room._id === selectedRoom) || {};
  };
  const currentRoom = getCurrentRoomData();
  const isRoomCreator = userProfile._id === currentRoom.creator?._id;

  const handleSaveEditedBook = async (editedBook) => {
    setIsLoading(true);
    try {
      let result;
      if (!editedBook._id) {
        // Book is from Google Books API and not in our DB yet
        result = await postGoogleBookToDBAndUser(editedBook);
      } else {
        // Book is already in our DB
        result = await addBookToUserLibrary(editedBook._id, {
          title: editedBook.title,
          authors: editedBook.authors,
          pages: editedBook.pages,
          genre: editedBook.genre,
          description: editedBook.description,
        });
      }

      if (!result.success) {
        setSnackbar({
          open: true,
          message: result.message || "Failed to save book",
          severity: "error",
        });
      } else {
        setSelectedBook(result.book || editedBook);
        setSelectedBookId(result.book._id);
        updateUserProfile(result.user);
        localStorage.setItem("userData", JSON.stringify(result.user));

        setSnackbar({
          open: true,
          message: "Book saved successfully!",
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Error saving edited book:", error);
      setSnackbar({
        open: true,
        message: "Failed to save book data",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
      setIsEditDialogOpen(false);
    }
  };

  const handleCreateRoom = async () => {
    if (!roomName) {
      setDialogError("Please enter a room name");
      return;
    }
    if (!selectedBook) {
      setDialogError("Please select a book before creating the guild");
      return;
    }
    setIsLoading(true);
    try {
      const newRoom = {
        name: roomName,
        isPublic: isPublic,
        roomPassword: isPublic ? undefined : roomPassword,
        maxMembers: Number(maxMembers),
        currentBook: selectedBookId,
      };
      const result = await createNewRoom(newRoom);
      if (result.success) {
        const createdRoom = result.room;
        setRooms((prevRooms) => [...prevRooms, createdRoom]);

        setSelectedRoom(createdRoom._id);
        setSnackbar({
          open: true,
          message: "Room created successfully!",
          severity: "success",
        });
        // Reset form
        setRoomName("");
        setIsPublic(false);

        setRoomPassword("");
        setMaxMembers(10);
        setSelectedBook(null);
        setOpen(false); // Close the dialog
        setOpenBackdrop(false);
        setHasRooms(true);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      } else {
        throw new Error(result.message || "Failed to create room");
      }
    } catch (error) {
      console.error("Failed to create room", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to create room",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleLikeDiscussion = async (discussionId) => {
    try {
      // Optimistic update
      setRooms((prevRooms) =>
        prevRooms.map((room) =>
          room._id === selectedRoom
            ? {
                ...room,
                discussions: room.discussions.map((d) =>
                  d._id === discussionId
                    ? {
                        ...d,
                        likes: d.likes?.includes(userProfile._id)
                          ? d.likes.filter((id) => id !== userProfile._id)
                          : [...(d.likes || []), userProfile._id],
                      }
                    : d
                ),
              }
            : room
        )
      );

      // Make API call
      const result = await likeDiscussion(selectedRoom, discussionId);

      if (!result.success) {
        // Revert optimistic update if API call fails
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  discussions: room.discussions.map((d) =>
                    d._id === discussionId
                      ? {
                          ...d,
                          likes: d.likes?.filter(
                            (id) => id !== userProfile._id
                          ),
                        }
                      : d
                  ),
                }
              : room
          )
        );
        throw new Error(result.message || "Failed to like discussion");
      }
    } catch (error) {
      console.error("Error liking discussion:", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to like discussion",
        severity: "error",
      });
    }
  };

  const handleSwitchBook = async (newBook) => {
    try {
      let result;

      if (!newBook._id) {
        // If the book doesn't have an ID, save it first
        result = await postGoogleBookToDBAndUser(newBook);
        if (!result.success) {
          throw new Error(result.message || "Failed to save new book");
        }
      } else {
        // Book is already in our DB, add it to user's library if not already there
        result = await addBookToUserLibrary(newBook._id, {
          title: newBook.title,
          authors: newBook.authors,
          pages: newBook.pages,
          genre: newBook.genre,
          description: newBook.description,
        });
        if (!result.success) {
          throw new Error(
            result.message || "Failed to add book to user's library"
          );
        }
      }

      // Use the book ID directly from the result
      const bookId = result.book._id;

      setSelectedBook(result.book);
      setSelectedBookId(bookId);

      // Now use the book ID directly to update the room
      const updateResult = await updateRoomBook(selectedRoom, bookId);
      if (updateResult.success) {
        setRooms((prevRooms) =>
          prevRooms.map((room) =>
            room._id === selectedRoom
              ? {
                  ...room,
                  currentBook: result.book,
                  previousBooks: [
                    ...(room.previousBooks || []),
                    room.currentBook,
                  ],
                  discussions: [],
                }
              : room
          )
        );
        setSnackbar({
          open: true,
          message: "Book switched successfully!",
          severity: "success",
        });
      } else {
        throw new Error(updateResult.message || "Failed to switch book");
      }
    } catch (error) {
      console.error("Failed to switch book", error);
      setSnackbar({
        open: true,
        message: error.message || "Failed to switch book",
        severity: "error",
      });
    }
  };

  return (
    <>
      {isNonMobileScreens ? (
        <>
          {" "}
          <ContentGrid sx={{ gap: 4 }}>
            <LeftProfile />
            <Box sx={{ gridColumn: "span 2" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  Guild
                </Typography>
                <Box>
                  <Select
                    value={selectedRoom}
                    onChange={handleRoomChange}
                    size="small"
                    sx={{
                      "& .MuiSelect-select": {
                        py: 0.2,
                        px: 1,
                      },
                    }}
                    displayEmpty
                  >
                    {rooms.length === 0 ? (
                      <MenuItem value="" disabled>
                        No rooms available
                      </MenuItem>
                    ) : (
                      rooms.map((room) => (
                        <MenuItem
                          key={room._id}
                          value={room._id}
                          variant="body3"
                        >
                          {`${room.name} #${room.roomTag}`}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 1,
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleClickOpen}
                    startIcon={<AddIcon />}
                  >
                    <Typography noWrap variant="body2">
                      Create Guild
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => setIsJoinRoomDialogOpen(true)}
                  >
                    <Typography noWrap variant="body2">
                      Join Guild
                    </Typography>
                  </Button>

                  {isRoomCreator && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={handleRemoveRoom}
                    >
                      <Typography noWrap variant="body2">
                        Delete Guild
                      </Typography>
                    </Button>
                  )}
                </Box>
              </Box>
              <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ gap: 2, display: "flex", flexDirection: "row" }}>
                  <Card
                    sx={{
                      width: currentRoom.currentBook?.title ? "50%" : "100%",
                    }}
                  >
                    <CardContentDoublePadding>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 6 }}
                      >
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "600",
                              mb: currentRoom.currentBook?.title && 1,
                            }}
                          >
                            {currentRoom.currentBook?.title
                              ? "Current Book"
                              : "No Book Chosen Yet"}
                          </Typography>
                          {currentRoom.currentBook?.title && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  boxShadow: 3,
                                  width: 84,
                                  minWidth: 96,
                                  height: "100%",
                                  borderRadius: "6%",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={
                                    currentRoom.currentBook?.cover?.large ||
                                    currentRoom.currentBook?.cover?.medium ||
                                    currentRoom.currentBook?.cover?.small ||
                                    defaultCover
                                  }
                                  alt="book"
                                  sx={{ cursor: "pointer", borderRadius: "6%" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexDirection: "column",
                                  gap: 2,
                                }}
                              >
                                <Box>
                                  <Typography
                                    variant="body3"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    {currentRoom.currentBook?.title ||
                                      "No Current Book"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: "600",
                                      color: theme.palette.neutral.main,
                                    }}
                                  >
                                    by{" "}
                                    {currentRoom.currentBook?.authors?.join(
                                      ", "
                                    ) || "Unknown"}
                                  </Typography>
                                </Box>
                                {currentRoom.currentBook?.genre && (
                                  <Box sx={{ display: "flex", gap: 0.5 }}>
                                    {currentRoom.currentBook?.genre?.map(
                                      (tag) => (
                                        <Chip
                                          key={tag}
                                          label={tag}
                                          size="small"
                                          color="default"
                                          sx={{
                                            height: 20,
                                            fontSize: "0.7rem",
                                          }}
                                        />
                                      )
                                    )}
                                  </Box>
                                )}

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0.5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 0.5,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      Language:
                                    </Typography>
                                    <Typography variant="body2">
                                      {currentRoom.currentBook?.language ||
                                        "Unknown"}
                                    </Typography>
                                  </Box>
                                  <Typography variant="body2">
                                    Pages: {currentRoom.currentBook?.pages || 0}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                        {/* <Divider orientation="vertical" flexItem />
            <Box
              sx={{
                display: "flex",
                width: "full",
                height: "full",
                flexDirection: "column",
              }}
            >
              <Typography
                noWrap
                variant="body1"
                sx={{ fontWeight: "600", mb: 1 }}
              >
                Previous Books
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 3,
                }}
              >
                {currentRoom.previousBooks?.map((book) => (
                  <Box
                    key={book.id}
                    sx={{
                      boxShadow: 3,
                      width: 60,
                      minWidth: 60,
                      borderRadius: "6%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={book.cover || defaultCover}
                      alt="book"
                      sx={{ cursor: "pointer", borderRadius: "6%" }}
                    />
                  </Box>
                ))}
              </Box>
            </Box> */}
                      </Box>
                    </CardContentDoublePadding>
                  </Card>
                  {currentRoom.currentBook?.title && (
                    <Card sx={{ width: "50%" }}>
                      <CardContentDoublePadding>
                        <RoomStatistics currentRoom={currentRoom} />{" "}
                      </CardContentDoublePadding>
                    </Card>
                  )}
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ width: "100%" }}
                    onClick={() => setIsPostDialogOpen(true)}
                  >
                    Post Discussion
                  </Button>
                  {currentRoom.discussions && (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "700", mt: 3, mb: 1 }}
                    >
                      Discussion
                    </Typography>
                  )}
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    {currentRoom.discussions &&
                      currentRoom.discussions.map((discussion, index) =>
                        discussion && discussion.user ? (
                          <DiscussionCard
                            key={discussion._id || index}
                            discussion={discussion}
                            isCurrentUser={
                              discussion.user._id === userProfile._id
                            }
                            onEdit={() => setEditingDiscussion(discussion)}
                            onRemove={() => handleRemoveDiscussion(discussion)}
                            onLike={handleLikeDiscussion}
                            currentUserId={userProfile._id}
                          />
                        ) : null
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box sx={{ mt: "36px" }}>
              {isRoomCreator && (
                <BookSearchCard
                  onBookSelect={(book) => {
                    if (currentRoom.currentBook) {
                      handleSwitchBook(book);
                    } else {
                      setSelectedBook(book);
                      setIsEditDialogOpen(true);
                    }
                  }}
                  currentBook={currentRoom.currentBook}
                />
              )}
              <Card sx={{ mt: isRoomCreator ? 2 : 0 }}>
                <CardContentDoublePadding>
                  <Typography variant="body1" sx={{ fontWeight: "700", mb: 2 }}>
                    Members
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    {currentRoom.members?.map((member, index) => (
                      <Box
                        key={member.id || index}
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <Avatar
                          src={member.avatar || undefined}
                          alt={member.name || undefined}
                          sx={{ width: 50, height: 50 }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body3"
                              sx={{ fontWeight: "600" }}
                            >
                              {member.name}
                            </Typography>
                            {member._id === currentRoom.creator?._id && (
                              <Chip
                                label="Guild Master"
                                size="small"
                                color="default"
                                sx={{ height: 20, fontSize: "0.7rem" }}
                              />
                            )}
                            {/* reactivate after demo*/}
                            {/* {isRoomCreator &&
                              member._id !== userProfile._id && (
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => handleRemoveMember(member)}
                                  aria-label="remove member"
                                  sx={{ p: 0 }}
                                >
                                  <CloseIcon size="small" />
                                </IconButton>
                              )} */}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <Typography variant="body2">Progress:</Typography>
                            <LinearProgressBar
                              percentage={
                                currentRoom?.memberProgress.find(
                                  (progress) => progress.user._id === member._id
                                )?.progress || 0
                              }
                            />
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "600" }}
                            >
                              {Math.round(
                                currentRoom?.memberProgress.find(
                                  (progress) => progress.user._id === member._id
                                )?.progress || 0
                              )}
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </CardContentDoublePadding>
              </Card>
            </Box>
          </ContentGrid>
          <Dialog
            open={isRemoveDialogOpen}
            onClose={() => setIsRemoveDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to remove this room?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. All data associated with this room
                will be permanently deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsRemoveDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={confirmRemoveRoom} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <Box
              sx={{
                display: "flex", // Fix the typo here from "dislay" to "display"
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // Optional: Adds some padding to the Box for spacing
              }}
            >
              <DialogTitle>Create a New Guild</DialogTitle>
              <IconButton onClick={handleClose} sx={{ p: 0.5, pr: 3 }}>
                <ClearIcon />
              </IconButton>
            </Box>
            <DialogContent sx={{ pt: 0, pb: 0.5 }}>
              {dialogError && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {dialogError}
                </Typography>
              )}
              <TextField
                size="small"
                autoFocus
                margin="dense"
                label="Room Name"
                type="text"
                fullWidth
                variant="outlined"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!isPublic}
                    onChange={(e) => setIsPublic(!e.target.checked)}
                  />
                }
                label="Private Room"
              />
              {isPublic ? null : (
                <TextField
                  size="small"
                  margin="dense"
                  label="Room Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={roomPassword}
                  onChange={(e) => setRoomPassword(e.target.value)}
                />
              )}
              <FormControl fullWidth margin="dense" size="small">
                <InputLabel id="max-members-label">Max Members</InputLabel>
                <Select
                  labelId="max-members-label"
                  id="max-members"
                  value={maxMembers}
                  label="Max Members"
                  onChange={(e) => setMaxMembers(e.target.value)}
                >
                  {maxMemberOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Select Book</Typography>
                <BookSearchCard
                  onBookSelect={(book) => {
                    setSelectedBook(book);
                    setIsEditDialogOpen(true);
                  }}
                />
              </Box>
              {selectedBook && (
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Selected Book: {selectedBook.title} by{" "}
                  {selectedBook.authors?.join(", ")}
                </Typography>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 0.5, pr: 3 }}>
              <Button onClick={handleClose} color="neutral">
                Cancel
              </Button>
              <Button
                onClick={handleCreateRoom}
                color="primary"
                // disabled={!selectedBook}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isRemoveMemberDialogOpen}
            onClose={() => setIsRemoveMemberDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to remove this member?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action will remove the member from the room. They will no
                longer have access to this room's content.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsRemoveMemberDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={confirmRemoveMember} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isRemoveDiscussionDialogOpen}
            onClose={() => setIsRemoveDiscussionDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to remove this discussion?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. The discussion will be permanently
                deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsRemoveDiscussionDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={confirmRemoveDiscussion} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <EditBookDataDialog
            open={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            book={selectedBook || {}}
            onSave={handleSaveEditedBook}
          />
          <DialogEditNote
            open={editingDiscussion !== null}
            onClose={() => setEditingDiscussion(null)}
            onSave={handleEditDiscussion}
            initialNote={editingDiscussion?.readingNotes || ""}
            bookTitle={currentRoom.currentBook?.title || ""}
            logDate={editingDiscussion?.postDate || ""}
            logId={editingDiscussion?._id | ""}
          />
          <PostDiscussionDialog
            open={isPostDialogOpen}
            onClose={() => setIsPostDialogOpen(false)}
            onSubmit={handlePostDiscussion}
            lastPageRead={getCurrentUserLastPageRead() || 0}
            bookTotalPages={currentRoom.currentBook?.pages || 0}
          />
          <JoinRoomDialog
            open={isJoinRoomDialogOpen}
            onClose={() => setIsJoinRoomDialogOpen(false)}
            onJoin={handleJoinRoom}
          />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <Paper sx={{ padding: 3, maxWidth: 400, position: "relative" }}>
              <IconButton
                aria-label="close"
                onClick={() => setOpenBackdrop(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" gutterBottom>
                Ready to dive into book chat?
              </Typography>
              <Typography paragraph>
                Create your own reading squad or join an existing one. It's time
                to share your bookish thoughts!
              </Typography>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Start a Guild
              </Button>
              <Button
                onClick={() => setIsJoinRoomDialogOpen(true)}
                variant="outlined"
              >
                Find a Guild
              </Button>
            </Paper>
          </Backdrop>
        </>
      ) : (
        <>
          {" "}
          <Box sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Box>
                <Typography variant="h5" sx={{ fontWeight: "600", mb: 1 }}>
                  Guild
                </Typography>
                <Box>
                  <Select
                    value={selectedRoom}
                    onChange={handleRoomChange}
                    size="small"
                    sx={{ width: "100%", mb: 1 }}
                    displayEmpty
                  >
                    {rooms.length === 0 ? (
                      <MenuItem value="" disabled>
                        No rooms available
                      </MenuItem>
                    ) : (
                      rooms.map((room) => (
                        <MenuItem
                          key={room._id}
                          value={room._id}
                          variant="body3"
                        >
                          {`${room.name} #${room.roomTag}`}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={handleClickOpen}
                    fullWidth
                    startIcon={<AddIcon />}
                  >
                    <Typography noWrap variant="body2">
                      Create Guild
                    </Typography>
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    fullWidth
                    onClick={() => setIsJoinRoomDialogOpen(true)}
                  >
                    <Typography noWrap variant="body2">
                      Join Guild
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
                  <Card>
                    <CardContentDoublePadding>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 6 }}
                      >
                        <Box>
                          <Typography
                            variant="body1"
                            sx={{
                              fontWeight: "600",
                              mb: currentRoom.currentBook?.title && 1,
                            }}
                          >
                            {currentRoom.currentBook?.title
                              ? "Current Book"
                              : "No Book Chosen Yet"}
                          </Typography>
                          {currentRoom.currentBook?.title && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 2,
                              }}
                            >
                              <Box
                                sx={{
                                  boxShadow: 3,
                                  width: 84,
                                  minWidth: 80,
                                  height: "100%",
                                  borderRadius: "6%",
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  image={
                                    currentRoom.currentBook?.cover?.large ||
                                    currentRoom.currentBook?.cover?.medium ||
                                    currentRoom.currentBook?.cover?.small ||
                                    defaultCover
                                  }
                                  alt="book"
                                  sx={{ cursor: "pointer", borderRadius: "6%" }}
                                />
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  flexDirection: "column",
                                  gap: 1,
                                }}
                              >
                                <Box>
                                  <Typography
                                    variant="body3"
                                    sx={{ fontWeight: "700" }}
                                  >
                                    {currentRoom.currentBook?.title ||
                                      "No Current Book"}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: "600",
                                      color: theme.palette.neutral.main,
                                    }}
                                  >
                                    by{" "}
                                    {currentRoom.currentBook?.authors?.join(
                                      ", "
                                    ) || "Unknown"}
                                  </Typography>
                                </Box>
                                {currentRoom.currentBook?.genre && (
                                  <Box sx={{ display: "flex", gap: 0.5 }}>
                                    {currentRoom.currentBook?.genre?.map(
                                      (tag) => (
                                        <Chip
                                          key={tag}
                                          label={tag}
                                          size="small"
                                          color="default"
                                          sx={{
                                            height: 20,
                                            fontSize: "0.7rem",
                                          }}
                                        />
                                      )
                                    )}
                                  </Box>
                                )}

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0.5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 0.5,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      Language:
                                    </Typography>
                                    <Typography variant="body2">
                                      {currentRoom.currentBook?.language ||
                                        "Unknown"}
                                    </Typography>
                                  </Box>
                                  <Typography variant="body2">
                                    Pages: {currentRoom.currentBook?.pages || 0}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </CardContentDoublePadding>
                  </Card>
                  {currentRoom.currentBook?.title && (
                    <Card>
                      <CardContentDoublePadding>
                        <RoomStatistics currentRoom={currentRoom} />
                      </CardContentDoublePadding>
                    </Card>
                  )}
                </Box>

                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ width: "100%" }}
                    onClick={() => setIsPostDialogOpen(true)}
                  >
                    Post Discussion
                  </Button>
                  {/* <Card>
                    <CardContentDoublePadding>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ width: "100%" }}
                        onClick={() => setIsPostDialogOpen(true)}
                      >
                        Post Discussion
                      </Button>
                    </CardContentDoublePadding>
                  </Card> */}
                  {currentRoom.discussions && (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: "700", mt: 2.5, mb: 1 }}
                    >
                      Discussion
                    </Typography>
                  )}
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    {currentRoom.discussions &&
                      currentRoom.discussions.map((discussion, index) =>
                        discussion && discussion.user ? (
                          <DiscussionCard
                            key={discussion._id || index}
                            discussion={discussion}
                            isCurrentUser={
                              discussion.user._id === userProfile._id
                            }
                            onEdit={() => setEditingDiscussion(discussion)}
                            onRemove={() => handleRemoveDiscussion(discussion)}
                            onLike={handleLikeDiscussion}
                            currentUserId={userProfile._id}
                          />
                        ) : null
                      )}
                  </Box>
                </Box>
              </Box>
            </Box>
            {currentRoom.discussions && (
              <Box sx={{ mt: 2, mb: 2 }}>
                <Divider
                  variant="middle"
                  color={theme.palette.neutral.light}
                  sx={{ borderBottomWidth: "2px" }}
                />
              </Box>
            )}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <Card>
                <CardContentDoublePadding>
                  <Typography variant="body1" sx={{ fontWeight: "700", mb: 2 }}>
                    Members
                  </Typography>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
                  >
                    {currentRoom.members?.map((member, index) => (
                      <Box
                        key={member.id || index}
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <Avatar
                          alt={member.name}
                          src={member.avatar}
                          sx={{ width: 50, height: 50 }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body3"
                              sx={{ fontWeight: "600" }}
                            >
                              {member.name}
                            </Typography>
                            {member._id === currentRoom.creator?._id && (
                              <Chip
                                label="Guild Master"
                                size="small"
                                color="default"
                                sx={{ height: 20, fontSize: "0.7rem" }}
                              />
                            )}
                            {/* reactivate after demo*/}
                            {/* {isRoomCreator &&
                              member._id !== userProfile._id && (
                                <IconButton
                                  color="error"
                                  size="small"
                                  onClick={() => handleRemoveMember(member)}
                                  aria-label="remove member"
                                  sx={{ p: 0 }}
                                >
                                  <CloseIcon size="small" />
                                </IconButton>
                              )} */}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: 1,
                            }}
                          >
                            <Typography variant="body2">Progress:</Typography>
                            <LinearProgressBar
                              percentage={
                                currentRoom?.memberProgress.find(
                                  (progress) => progress.user._id === member._id
                                )?.progress || 0
                              }
                            />
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: "600" }}
                            >
                              {Math.round(
                                currentRoom?.memberProgress.find(
                                  (progress) => progress.user._id === member._id
                                )?.progress || 0
                              )}
                              %
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </CardContentDoublePadding>
              </Card>
              {isRoomCreator && (
                <BookSearchCard
                  onBookSelect={(book) => {
                    if (currentRoom.currentBook) {
                      handleSwitchBook(book);
                    } else {
                      setSelectedBook(book);
                      setIsEditDialogOpen(true);
                    }
                  }}
                  currentBook={currentRoom.currentBook}
                />
              )}

              {isRoomCreator && (
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleRemoveRoom}
                  fullWidth
                  sx={{ py: 1 }}
                >
                  Delete Guild
                </Button>
              )}
            </Box>
          </Box>
          <Dialog
            open={isRemoveDialogOpen}
            onClose={() => setIsRemoveDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to remove this room?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. All data associated with this room
                will be permanently deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsRemoveDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={confirmRemoveRoom} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <Box
              sx={{
                display: "flex", // Fix the typo here from "dislay" to "display"
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                // Optional: Adds some padding to the Box for spacing
              }}
            >
              <DialogTitle>Create a New Guild</DialogTitle>
              <IconButton onClick={handleClose} sx={{ p: 0.5, pr: 3 }}>
                <ClearIcon />
              </IconButton>
            </Box>
            <DialogContent sx={{ pb: 0.5 }}>
              {dialogError && (
                <Typography color="error" sx={{ mb: 2 }}>
                  {dialogError}
                </Typography>
              )}
              <TextField
                size="small"
                autoFocus
                margin="dense"
                label="Room Name"
                type="text"
                fullWidth
                variant="outlined"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={!isPublic}
                    onChange={(e) => setIsPublic(!e.target.checked)}
                  />
                }
                label="Private Room"
              />
              {!isPublic && (
                <TextField
                  size="small"
                  margin="dense"
                  label="Room Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={roomPassword}
                  onChange={(e) => setRoomPassword(e.target.value)}
                />
              )}
              <FormControl fullWidth margin="dense" size="small">
                <InputLabel id="max-members-label">Max Members</InputLabel>
                <Select
                  labelId="max-members-label"
                  id="max-members"
                  value={maxMembers}
                  label="Max Members"
                  onChange={(e) => setMaxMembers(e.target.value)}
                >
                  {maxMemberOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1">Select Book</Typography>
                <BookSearchCard
                  onBookSelect={(book) => {
                    setSelectedBook(book);
                    setIsEditDialogOpen(true);
                  }}
                />
              </Box>
              {selectedBook && (
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  Selected Book: {selectedBook.title} by{" "}
                  {selectedBook.authors?.join(", ")}
                </Typography>
              )}
            </DialogContent>
            <DialogActions sx={{ p: 0.5, pr: 3 }}>
              <Button onClick={handleClose} color="neutral">
                Cancel
              </Button>
              <Button
                onClick={handleCreateRoom}
                color="primary"
                // disabled={!selectedBook}
              >
                Create
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isRemoveMemberDialogOpen}
            onClose={() => setIsRemoveMemberDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
            fullScreen={!isNonMobileScreens}
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to remove this member?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action will remove the member from the room. They will no
                longer have access to this room's content.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsRemoveMemberDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={confirmRemoveMember} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={isRemoveDiscussionDialogOpen}
            onClose={() => setIsRemoveDiscussionDialogOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
            fullScreen={!isNonMobileScreens}
          >
            <DialogTitle id="alert-dialog-title">
              {"Are you sure you want to remove this discussion?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This action cannot be undone. The discussion will be permanently
                deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setIsRemoveDiscussionDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={confirmRemoveDiscussion} color="error" autoFocus>
                Remove
              </Button>
            </DialogActions>
          </Dialog>
          <EditBookDataDialog
            open={isEditDialogOpen}
            onClose={() => setIsEditDialogOpen(false)}
            book={selectedBook || {}}
            onSave={handleSaveEditedBook}
          />
          <DialogEditNote
            open={editingDiscussion !== null}
            onClose={() => setEditingDiscussion(null)}
            onSave={handleEditDiscussion}
            initialNote={editingDiscussion?.readingNotes || ""}
            bookTitle={currentRoom.currentBook?.title || ""}
            logDate={editingDiscussion?.postDate || ""}
            logId={editingDiscussion?._id || ""}
          />
          <PostDiscussionDialog
            open={isPostDialogOpen}
            onClose={() => setIsPostDialogOpen(false)}
            onSubmit={handlePostDiscussion}
            bookId={currentRoom.currentBook?._id || ""}
            lastPageRead={getCurrentUserLastPageRead() || 0}
            bookTotalPages={currentRoom.currentBook?.pages || 0}
          />
          <JoinRoomDialog
            open={isJoinRoomDialogOpen}
            onClose={() => setIsJoinRoomDialogOpen(false)}
            onJoin={handleJoinRoom}
          />
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
          >
            <Paper sx={{ m: 3, p: 3, maxWidth: 400 }}>
              <Typography variant="h6" gutterBottom>
                Ready to dive into book chat?
              </Typography>
              <Typography paragraph>
                Create your own guild or join an existing one. It's time to
                share your bookish thoughts!
              </Typography>
              <Button
                onClick={handleClickOpen}
                variant="contained"
                sx={{ mr: 2 }}
              >
                Start a Guild
              </Button>
              <Button
                onClick={() => setIsJoinRoomDialogOpen(true)}
                variant="outlined"
              >
                Find a Guild
              </Button>
            </Paper>
          </Backdrop>
        </>
      )}
    </>
  );
};

export default Rooms;
