import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Link,
  useTheme,
} from "@mui/material";
import LineChartWidget from "../widgets/LineChartWidget";
import HomeYearlyProgressWidget from "../widgets/HomeYearlyProgressWidget";
import HomeThreeStatsWidget from "../widgets/HomeThreeStatsWidget";
import LeftProfile from "../../layouts/leftProfile";
import ContentGrid from "../../components/ContentGrid";
import HomeBookProgressLargeWidget from "../widgets/HomeBookProgressLargeWidget";
import {
  getUserPersonalLineChartData,
  getUserProfileData,
} from "../../api/userApi.js";
import { parsePersonalLineData } from "../../utility/utils.js";
import HelpResources from "../helpResources/index.jsx";
import { useSnackbar } from "../../context/SnackbarContext.js";
import { UserContext } from "../../context/UserContext";
import { useLoading } from "../../context/LoadingContext.js";
import GenreChartWidget from "../widgets/GenreChartWidget.jsx";
import StreakCard from "../widgets/StreakCard.jsx";
import ProfileWidget from "../widgets/ProfileWidget.jsx";
import PremiumSubscriptionWidget from "../widgets/PremiumSubscriptionWidget.jsx";

const HomePage = ({ setShowNavbar }) => {
  const [personalLineData, setPersonalLineData] = useState([]);
  const [sliceParams, setSliceParams] = useState(7);
  const snackbar = useSnackbar();
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const { setIsLoading } = useLoading();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const theme = useTheme();
  const [openAnnouncement, setOpenAnnouncement] = useState(false);

  useEffect(() => {
    setShowNavbar(true); // Show navbar when component mounts
  }, [userProfile, setShowNavbar]);

  useEffect(() => {
    setIsLoading(false);
  }, [userProfile]);

  useEffect(() => {
    fetchPersonalLineData();
  }, [sliceParams]);

  useEffect(() => {
    if (userProfile.name) {
      const showMessage = localStorage.getItem("showMessage");
      if (showMessage === "login") {
        snackbar(`Welcome back ${userProfile.name && userProfile.name}!`);
        setOpenAnnouncement(true); // Show announcement dialog on login

        // Reset the flag in localStorage
        localStorage.removeItem("showMessage");
      } else if (showMessage === "register") {
        snackbar(
          `Welcome to ReadersGuild ${
            userProfile.name ? userProfile.name : ""
          }, Have fun!`
        );
        setOpenAnnouncement(true); // Show announcement dialog on register

        // Reset the flag in localStorage
        localStorage.removeItem("showMessage");
      } else if (showMessage === "updateProfile") {
        snackbar("Profile successfully updated.");
        localStorage.removeItem("showMessage");
      }
    }
  }, [userProfile]);

  const pullAlignmentData = (alignment) => {
    alignment === "Monthly" ? setSliceParams(30) : setSliceParams(7);
  };

  const fetchPersonalLineData = async () => {
    setIsLoading(true);
    try {
      const result = await getUserPersonalLineChartData();
      const filledData = parsePersonalLineData(result.data.lineChartData);
      const processedData = filledData.slice(0, sliceParams).reverse();
      const personalLineChartData = [{ id: "kallula", data: processedData }];
      setPersonalLineData(personalLineChartData);
    } catch (error) {
      console.error("Error fetching line chart data:", error);
      snackbar("Failed to load chart data. Please try again later.", {
        severity: "error",
      });
      setPersonalLineData([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseAnnouncement = () => {
    setOpenAnnouncement(false);
  };

  return (
    <>
      <Dialog open={openAnnouncement} onClose={handleCloseAnnouncement}>
        <DialogTitle>Welcome to ReadersGuild Beta!</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Thank you for joining ReadersGuild beta program! Currently, the app
            supports only solo tracking, as the group tracking system is still
            under development. I appreciate your patience and understanding
            during this phase.
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            If you encounter any issues or have feedback, feel free to reach out
            to me on{" "}
            <Link
              href="https://www.instagram.com/your_instagram"
              target="_blank"
              rel="noopener"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "inherit",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              Instagram
            </Link>{" "}
            or{" "}
            <Link
              href="https://www.twitter.com/your_twitter"
              target="_blank"
              rel="noopener"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "inherit",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              X
            </Link>
            .
          </Typography>
          <Typography sx={{ textAlign: "right", fontWeight: "500" }}>
            ~ Fabio
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAnnouncement} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {isNonMobileScreens ? (
        <ContentGrid sx={{ gap: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gridColumn: "span 1",
              gap: 2,
            }}
          >
            <LeftProfile />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gridColumn: "span 2",
              gap: 2,
            }}
          >
            <HomeBookProgressLargeWidget />
            <HomeThreeStatsWidget />
            <LineChartWidget
              onAlignmentChange={pullAlignmentData}
              lineData={personalLineData}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gridColumn: "span 1",
              gap: 2,
            }}
          >
            <Box>
              <HomeYearlyProgressWidget />
            </Box>
            <Box>
              <HelpResources />
            </Box>
            {userProfile.currentPlan === "free" && (
              <Box>
                <PremiumSubscriptionWidget />
              </Box>
            )}
          </Box>
        </ContentGrid>
      ) : (
        <Box sx={{ width: "100%", gap: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            <ProfileWidget />
            <HomeBookProgressLargeWidget />
            <HomeThreeStatsWidget />
            <LineChartWidget
              onAlignmentChange={pullAlignmentData}
              lineData={personalLineData}
            />
            <GenreChartWidget />
            <StreakCard />
            <Box>
              <HomeYearlyProgressWidget />
            </Box>
            <Box>
              <HelpResources />
            </Box>
            {userProfile.currentPlan === "free" && (
              <PremiumSubscriptionWidget />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default HomePage;
