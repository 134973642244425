import { Box, Typography, useMediaQuery } from "@mui/material";
import { Button, Suspense, useEffect, useState } from "react";
import BookCardWidget from "../../components/BookCardWidget";
import BookSearchBar from "../../components/BookSearchBar";
import LeftProfile from "../../layouts/leftProfile";
import ContentGrid from "../../components/ContentGrid";
import { getBooks } from "../../api/bookApi";
import { getUserBooks } from "../../api/userApi";
import PageFrame from "../../components/PageFrame";
import BookCategories from "../../components/BookCategories";

const BooksPage = ({ isMarketplace = false }) => {
  const [books, setBooks] = useState([]);
  const [category, setCategory] = useState("all");
  const [bookOwned, setBookOwned] = useState(false);
  const [searchResultBooks, setSearchResultBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userBooks, setUserBooks] = useState([]);
  const [bookISBN10, setBookISBN10] = useState([]);
  const [bookISBN13, setBookISBN13] = useState([]);
  const [filteredBooks, setFilteredBooks] = useState([]);
  // const [startIndex, setStartIndex] = useState(0);
  // const [totalItems, setTotalItems] = useState(0);
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isExtraSmallScreen = useMediaQuery("(min-width: 480px)");
  const isTwoBooksScreen = useMediaQuery("(min-width: 374px)");

  useEffect(() => {
    const fetchData = async () => {
      await fetchBooksData();
      await fetchUserBooksData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (userBooks.length > 0) {
      setISBNArray();
    }
    setFilteredBooks(filterBooksByCategory(userBooks));
  }, [userBooks, category]);

  const fetchBooksData = async () => {
    try {
      const result = await getBooks();
      setBooks(result.data);
    } catch (error) {
      console.error("Error fetching books:", error);
    }
  };

  const fetchUserBooksData = async () => {
    try {
      const result = await getUserBooks();
      setFilteredBooks(filterBooksByCategory(result.data, category));
      setUserBooks(result.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching userBooks:", error);
      setIsLoading(false);
    }
  };
  const handleAddNewBook = (newBook) => {
    const formattedNewBook = {
      book: {
        _id: newBook._id || newBook.id,
        title: newBook.title,
        authors: newBook.authors,
        pages: newBook.pages,
        genre: newBook.genre,
        description: newBook.description,
        cover: newBook.cover,
      },
      status: "tbr_owned",
    };

    setUserBooks((prevBooks) => {
      const updatedBooks = [...prevBooks, formattedNewBook];
      setFilteredBooks(filterBooksByCategory(updatedBooks));
      return updatedBooks;
    });

    if (isMarketplace) {
      setSearchResultBooks((prevSearchBooks) => [...prevSearchBooks, newBook]);
    }
  };

  const setISBNArray = () => {
    const isbn13Array = [];
    const isbn10Array = [];
    for (let book of userBooks) {
      isbn10Array.push(book.book.isbn10);
      isbn13Array.push(book.book.isbn13);
    }
    setBookISBN10(isbn10Array);
    setBookISBN13(isbn13Array);
  };

  const handleBookUpdate = (updatedBook) => {
    // Optimistically update the UI
    const updatedUserBooks = userBooks.map((book) =>
      book.book._id === updatedBook._id ? { ...book, book: updatedBook } : book
    );
    setUserBooks(updatedUserBooks);
    setFilteredBooks(filterBooksByCategory(updatedUserBooks));

    // Optionally, update the searchResultBooks if the updated book is in there
    if (isMarketplace) {
      const updatedSearchResultBooks = searchResultBooks.map((book) =>
        book._id === updatedBook._id ? { ...book, ...updatedBook } : book
      );
      setSearchResultBooks(updatedSearchResultBooks);
    }
  };

  const handleBookStatusChange = (bookId, newStatus) => {
    const updatedUserBooks = userBooks.map((book) =>
      book.book._id === bookId ? { ...book, status: newStatus } : book
    );
    setUserBooks(updatedUserBooks);
    setFilteredBooks(filterBooksByCategory(updatedUserBooks));
  };

  const handleUserBooksChange = (bookIdValue) => {
    const updatedUserBooks = userBooks.filter((book) => {
      return book.book._id.toString() !== bookIdValue.toString();
    });
    setUserBooks(updatedUserBooks);
  };

  const handleBooksSearchResult = (books) => {
    const newBooks = books.map((book) => {
      book.bookOwned =
        bookISBN10.includes(book.isbn10) || bookISBN13.includes(book.isbn13);
      return book;
    });
    setSearchResultBooks(newBooks);
  };

  const arrayOfUserBooksId = userBooks.map((userBook) => userBook.book._id);

  const filterBooksByCategory = (books) => {
    return books.filter((bookItem) => {
      const status = bookItem.status || bookItem.book?.status;
      switch (category) {
        case "tbr_unbought":
          return status === "tbr_unbought";
        case "finished":
          return status === "finished";
        case "tbr_owned":
          return status === "tbr_owned";
        case "in_progress":
          return status === "in_progress";
        default:
          return true;
      }
    });
  };

  // const handleShowMore = () => {
  //   // Increment startIndex by the number of books per page (e.g., 40)
  //   setStartIndex(startIndex + 40);
  //   // Call fetchBooksData to fetch additional books
  //   fetchBooksData();
  // };

  // const handleTotalItems = (itemCount) => {
  //   setTotalItems(itemCount);
  // };

  // const bookIsOwned = () => {
  //   if (arrayOfUserBooksId.includes(books._id)) {
  //     setBookOwned(true);
  //   }
  // };

  return (
    <>
      {isNonMobileScreens ? (
        <ContentGrid sx={{ gap: 4 }}>
          <LeftProfile />
          <Box
            sx={{
              gridColumn: "span 3",
            }}
          >
            <Box>
              {/* renewed */}
              <Typography variant="h5" sx={{ fontWeight: "600", mb: 2 }}>
                {isMarketplace ? "Marketplace" : "My Library"}
              </Typography>
              {isMarketplace && (
                <BookSearchBar
                  isMarketplace={isMarketplace}
                  bookSearchResult={handleBooksSearchResult}
                />
              )}
            </Box>
            {!isMarketplace && (
              <Box sx={{ mb: 2.5 }}>
                <BookCategories
                  category={category}
                  onCategoryChange={setCategory}
                />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                gap: 5,
                flexWrap: "wrap",
              }}
            >
              {/* Suspense fallback can also use a loading component inside fallback  */}
              {isMarketplace &&
                (searchResultBooks.length > 0 ? (
                  <Suspense fallback={<p>Loading...</p>}>
                    {searchResultBooks.map((book, index) => (
                      <BookCardWidget
                        key={index}
                        googleBook={book}
                        bookProvider={book.bookProvider}
                        cover={book.cover}
                        bookId={book.id}
                        isMarketplace={isMarketplace}
                        bookTitle={book.title}
                        bookAuthors={book.authors}
                        bookGenre={book.genre}
                        onBookUpdate={handleAddNewBook}
                        averageRating={book.averageRating}
                        ratingCount={book.ratingCount}
                        bookOwned={
                          book._id
                            ? arrayOfUserBooksId.includes(book._id)
                            : false
                        }
                      />
                    ))}
                  </Suspense>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* <Typography variant="h2" sx={{ fontWeight: 700 }}>
                      Put Book Name in the Search Bar
                    </Typography> */}
                  </Box>
                ))}

              {!isMarketplace && filteredBooks.length > 0 ? (
                filteredBooks.map((userBook, index) => (
                  <BookCardWidget
                    key={index}
                    bookId={userBook.book._id}
                    userBookData={userBook.book}
                    onUpdateUserBooksParent={handleUserBooksChange}
                    onStatusChange={handleBookStatusChange}
                    bookStatus={userBook.status}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* renewed */}
                </Box>
              )}
            </Box>
          </Box>
        </ContentGrid>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            {/* <LeftProfile /> */}
            <PageFrame
              title={isMarketplace ? "Marketplace" : "My Library"}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "end",
                gap: 0.5,
              }}
            >
              {!isMarketplace && (
                <Typography
                  variant="subtitle1"
                  sx={{ fontSize: "12px", fontWeight: "400" }}
                >
                  You have {filteredBooks.length} books in this category
                </Typography>
              )}
            </PageFrame>
            {isMarketplace && (
              <Box sx={{ mt: 1 }}>
                <BookSearchBar
                  isMarketplace={isMarketplace}
                  bookSearchResult={handleBooksSearchResult}
                />
              </Box>
            )}
            {!isMarketplace && (
              <BookCategories
                category={category}
                onCategoryChange={setCategory}
              />
            )}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                mt: isNonMobileScreens ? 0 : 1.5,
                // gap: 3,
                justifyContent: isTwoBooksScreen ? "flex-start" : "center",
                gap: isExtraSmallScreen ? 1.2 : 3,
                width: "100%",
                "& > :not(:last-child)": {
                  marginBottom: "1.5rem",
                },
              }}
            >
              {isMarketplace && searchResultBooks.length > 0 && (
                <Suspense fallback={<p>Loading...</p>}>
                  {searchResultBooks.map((book) => (
                    <BookCardWidget
                      key={book.id}
                      googleBook={book}
                      bookProvider={book.bookProvider}
                      cover={book.cover}
                      bookId={book.id}
                      isMarketplace={isMarketplace}
                      bookTitle={book.title}
                      bookAuthors={book.authors}
                      bookGenre={book.genre}
                      averageRating={book.averageRating}
                      ratingCount={book.ratingCount}
                      bookOwned={arrayOfUserBooksId.includes(book._id)}
                    />
                  ))}
                </Suspense>
              )}
              {!isMarketplace && filteredBooks.length > 0 ? (
                filteredBooks.map((userBook, index) => (
                  <BookCardWidget
                    key={index}
                    bookId={userBook.book._id}
                    userBookData={userBook.book}
                    onUpdateUserBooksParent={handleUserBooksChange}
                    onStatusChange={handleBookStatusChange}
                    bookStatus={userBook.status}
                  />
                ))
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BooksPage;
