import { Box, Typography, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";
import ReadingLogMode from "./readingLogMode";
import BookProfileWidget from "../../widgets/BookProfileWidget";
import LogHistoryWidget from "../../widgets/LogHistoryWidget";
import LineChartWidget from "../../widgets/LineChartWidget";
import { personalLineDataWeek } from "../../../data/mockData";
import ToggleLogsNotes from "../../../components/ToggleLogsNotes";
import NoteWidget from "../../widgets/NoteWidget";
import { useParams } from "react-router-dom";
import { getBook } from "../../../api/bookApi";
import {
  getUserBookProgress,
  getUserReadingLogsByBookId,
  getUserPersonalLineChartData,
} from "../../../api/userApi";
import dayjs from "dayjs";
import BookFinishGoalWidget from "../../widgets/BookFinishGoalWidget";
import { useSnackbar } from "../../../context/SnackbarContext";
import { parsePersonalLineData } from "../../../utility/utils";
import { useLoading } from "../../../context/LoadingContext";
import { updateReadingLog } from "../../../api/logApi";

const ManualReadingSession = () => {
  const [readingLogs, setReadingLogs] = useState([]);
  const [book, setBook] = useState({});
  const [sliceParams, setSliceParams] = useState(7);
  const [bookProgress, setBookProgress] = useState({});
  const snackbar = useSnackbar();
  const { bookId } = useParams();
  const [bookProgressPercentage, setBookProgressPercentage] = useState(true);
  const [personalLineData, setPersonalLineData] = useState([]);
  const { setIsLoading } = useLoading();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const [isDataLoading, setIsDataLoading] = useState(true); // New state for data loading
  const [viewMode, setViewMode] = useState("notes");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await fetchUserReadingLogsByBookId();
        await fetchBookData();
        await fetchBookProgress();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
        setIsDataLoading(false); // Data loading completed
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (bookProgress && book) {
      calculateBookProgress();
    }
  }, [bookProgress]);

  useEffect(() => {
    fetchBookProgress();
  }, [readingLogs]);

  useEffect(() => {
    fetchPersonalLineData();
  }, [sliceParams]);

  const fetchPersonalLineData = async () => {
    const result = await getUserPersonalLineChartData();
    const filledData = parsePersonalLineData(result.data.lineChartData);
    const processedData = filledData.slice(0, sliceParams).reverse();
    const personalLineChartData = [{ id: "kallula", data: processedData }];
    setPersonalLineData(personalLineChartData);
  };

  const fetchUserReadingLogsByBookId = async () => {
    try {
      const result = await getUserReadingLogsByBookId(bookId);
      const resultArrayReverse = result.data.slice().reverse();
      if (JSON.stringify(resultArrayReverse) !== JSON.stringify(readingLogs)) {
        setReadingLogs(resultArrayReverse);
      }
      return resultArrayReverse.length > 0
        ? resultArrayReverse[0].lastPageRead || 0
        : 0;
    } catch (error) {
      console.error("Error fetching readingLogs:", error);
    }
  };

  const handleLogUpdate = async (logId, updatedNote, updatedPrivateSwitch) => {
    try {
      // Call your API to update the note
      await updateReadingLog(logId, updatedNote, updatedPrivateSwitch);

      // Update the local state
      setReadingLogs((prevLogs) =>
        prevLogs.map((log) =>
          log._id === logId
            ? {
                ...log,
                readingNotes: updatedNote,
                privateSwitch: updatedPrivateSwitch,
              }
            : log
        )
      );
    } catch (error) {
      console.error("Error updating reading log note:", error);
      // You might want to show an error message to the user here
    }
  };

  const fetchBookData = async () => {
    try {
      const result = await getBook(bookId);
      setBook(result.data);
    } catch (error) {
      console.error("Error fetching Book: ", error);
    }
  };

  const fetchBookProgress = async () => {
    try {
      const result = await getUserBookProgress(bookId);
      setBookProgress(result.data);
    } catch (error) {
      console.error("Error fetching book progress: ", error);
    }
  };

  const handleUpdateRecentLog = (recentLog) => {
    const progressPercentage = Math.round(
      (bookProgress.lastPageRead / book.pages) * 100
    );
    const newAmtPageRead = recentLog.amountPageRead;
    const lastYValue =
      personalLineData[0].data[personalLineData[0].data.length - 1].y;
    const updatedData = personalLineData[0].data.map((item, index) => {
      if (index === personalLineData[0].data.length - 1) {
        return { ...item, y: item.y + newAmtPageRead };
      } else {
        return item;
      }
    });

    const newPersonalLineData = [{ ...personalLineData[0], data: updatedData }];
    setPersonalLineData(newPersonalLineData);
    setBookProgressPercentage(progressPercentage);
    setReadingLogs([...readingLogs, recentLog]);
    snackbar(`Successfully created new log journal`);
  };

  const handleUpdateNote = async (logId, updatedNote, updatedPrivateSwitch) => {
    try {
      await updateReadingLog(logId, updatedNote, updatedPrivateSwitch);
      setReadingLogs((prevLogs) =>
        prevLogs.map((log) =>
          log._id === logId ? { ...log, readingNotes: updatedNote } : log
        )
      );
      snackbar("Note updated successfully");
    } catch (error) {
      console.error("Error updating reading log note:", error);
      snackbar("Failed to update note", "error");
    }
  };

  const handleLogRemoval = (logId) => {
    try {
      const previousReadingLog = readingLogs;
      const result = previousReadingLog.filter((log) => log._id !== logId);
      setReadingLogs(result);
    } catch (error) {
      console.error("Error removing reading log: ", error);
    }
  };

  const calculateBookProgress = () => {
    const progressPercentage = Math.round(
      (bookProgress.lastPageRead / book.pages) * 100
    );
    setBookProgressPercentage(progressPercentage);
  };

  const pullAlignmentData = (alignment) => {
    alignment === "Monthly" ? setSliceParams(30) : setSliceParams(7);
  };

  if (isDataLoading) {
    return <div>Loading...</div>; // Show a loading indicator while data is being fetched
  }

  const hasValidNotes = (notes) => {
    if (!notes) return false; // Handle case where notes is null or undefined
    const cleanedNotes = notes.replace(/<[^>]*>/g, "").trim();
    return cleanedNotes.length > 0;
  };

  return (
    <>
      {isNonMobileScreens ? (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(12,1fr)",
              gap: 3,
            }}
          >
            <Box sx={{ gridColumn: "span 4" }}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <BookProfileWidget
                  bookId={bookId}
                  bookProfile={book}
                  bookProfileStatus={bookProgress.status}
                />
                <BookFinishGoalWidget
                  bookFinishGoalDate={bookProgress.finishGoalDate}
                  bookProgressNum={bookProgressPercentage}
                />

                <LineChartWidget
                  onAlignmentChange={pullAlignmentData}
                  lineData={personalLineData}
                />
              </Box>
            </Box>

            <Box sx={{ gridColumn: "span 8" }}>
              <Box>
                <Box sx={{ mb: 2 }}>
                  <ReadingLogMode
                    fetchRecentLog={fetchUserReadingLogsByBookId}
                    bookFinished={bookProgress.bookFinished}
                    bookTotalPages={book ? book.pages : 0} // Check if book exists before accessing its pages property
                    OnUpdateRecentLog={handleUpdateRecentLog}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 3,
                    mb: 1,
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">
                    {viewMode === "logs"
                      ? `Book's Reading Log (${readingLogs.length})`
                      : `Book's Reading Notes (${
                          readingLogs.filter((log) => log.readingNotes).length
                        })`}
                  </Typography>
                  <ToggleLogsNotes
                    value={viewMode}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setViewMode(newValue);
                      }
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                    mt: 1,
                  }}
                >
                  {viewMode === "logs"
                    ? readingLogs.map((readingLog) => (
                        <LogHistoryWidget
                          key={readingLog._id}
                          logId={readingLog._id}
                          bookTitle={book.title}
                          bookTotalPages={book.pages}
                          isPicture={false}
                          sessionMode={"Regular"}
                          bookAuthor={book.authors}
                          logDate={dayjs(readingLog.postDate).format(
                            "dddd, MMMM D, YYYY"
                          )}
                          isDiscussion={readingLog.isDiscussion}
                          roomTag={
                            readingLog.room
                              ? `#${readingLog.room.roomTag}`
                              : null
                          }
                          lastPageRead={readingLog.lastPageRead}
                          startPageRead={readingLog.startPageRead}
                          amountPageRead={readingLog.amountPageRead}
                          pageOrPercent={readingLog.pageNumberOrPercentage}
                          privateSwitch={readingLog.privateSwitch}
                          finishGoalDate={readingLog.finishGoalDate}
                          notes={readingLog.readingNotes || ""} // Provide default empty string
                          onUpdateRemoveLog={handleLogRemoval}
                          onUpdateNote={handleLogUpdate}
                        />
                      ))
                    : readingLogs
                        .filter((log) => hasValidNotes(log.readingNotes))
                        .map((readingLog) => (
                          <NoteWidget
                            key={readingLog._id}
                            bookTitle={readingLog.book.title}
                            bookAuthors={readingLog.book.authors}
                            privateSwitch={readingLog.privateSwitch}
                            lastPageRead={readingLog.lastPageRead}
                            startPageRead={readingLog.startPageRead}
                            amountPageRead={readingLog.amountPageRead}
                            bookTotalPage={readingLog.book.pages}
                            notes={readingLog.readingNotes || ""}
                            logId={readingLog._id}
                            roomTag={
                              readingLog.room
                                ? `#${readingLog.room.roomTag}`
                                : null
                            }
                            isDiscussion={readingLog.isDiscussion}
                            onUpdateRemoveLog={handleLogRemoval}
                            logDate={dayjs(readingLog.postDate).format(
                              "MMM D, YYYY"
                            )}
                            onUpdateNote={handleUpdateNote}
                          />
                        ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <BookProfileWidget
              bookId={bookId}
              bookProfile={book}
              bookProfileStatus={bookProgress.status}
            />

            <ReadingLogMode
              fetchRecentLog={fetchUserReadingLogsByBookId}
              bookFinished={bookProgress.bookFinished}
              bookTotalPages={book ? book.pages : 0} // Check if book exists before accessing its pages property
              OnUpdateRecentLog={handleUpdateRecentLog}
            />

            <BookFinishGoalWidget
              bookFinishGoalDate={bookProgress.finishGoalDate}
              bookProgressNum={bookProgressPercentage}
            />

            <LineChartWidget
              onAlignmentChange={pullAlignmentData}
              lineData={personalLineData}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mt: 1,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1">
                {viewMode === "logs"
                  ? `Book's Reading Log (${readingLogs.length})`
                  : `Book's Reading Notes (${
                      readingLogs.filter((log) => log.readingNotes).length
                    })`}
              </Typography>
              <ToggleLogsNotes
                value={viewMode}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setViewMode(newValue);
                  }
                }}
              />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {viewMode === "logs"
                ? readingLogs.map((readingLog) => (
                    <LogHistoryWidget
                      key={readingLog._id}
                      logId={readingLog._id}
                      bookTitle={book.title}
                      bookTotalPages={book.pages}
                      isPicture={false}
                      sessionMode={"Regular"}
                      bookAuthor={book.authors}
                      logDate={dayjs(readingLog.postDate).format(
                        "dddd, MMMM D, YYYY"
                      )}
                      isDiscussion={readingLog.isDiscussion}
                      roomTag={
                        readingLog.room ? `#${readingLog.room.roomTag}` : null
                      }
                      lastPageRead={readingLog.lastPageRead}
                      startPageRead={readingLog.startPageRead}
                      amountPageRead={readingLog.amountPageRead}
                      pageOrPercent={readingLog.pageNumberOrPercentage}
                      privateSwitch={readingLog.privateSwitch}
                      finishGoalDate={readingLog.finishGoalDate}
                      notes={readingLog.readingNotes || ""} // Provide default empty string
                      onUpdateRemoveLog={handleLogRemoval}
                      onUpdateNote={handleLogUpdate}
                    />
                  ))
                : readingLogs
                    .filter((log) => hasValidNotes(log.readingNotes))
                    .map((readingLog) => (
                      <NoteWidget
                        key={readingLog._id}
                        bookTitle={readingLog.book.title}
                        bookAuthors={readingLog.book.authors}
                        privateSwitch={readingLog.privateSwitch}
                        lastPageRead={readingLog.lastPageRead}
                        startPageRead={readingLog.startPageRead}
                        amountPageRead={readingLog.amountPageRead}
                        bookTotalPage={readingLog.book.pages}
                        notes={readingLog.readingNotes || ""}
                        logId={readingLog._id}
                        roomTag={
                          readingLog.room ? `#${readingLog.room.roomTag}` : null
                        }
                        isDiscussion={readingLog.isDiscussion}
                        onUpdateRemoveLog={handleLogRemoval}
                        logDate={dayjs(readingLog.postDate).format(
                          "MMM D, YYYY"
                        )}
                        onUpdateNote={handleUpdateNote}
                      />
                    ))}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ManualReadingSession;
