import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 2, sm: 6 },
        pb: { xs: 4, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        variant={isSuperSmallScreen ? "h5" : "h2"}
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography
              component="h3"
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              How do I start tracking my reading progress?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body3"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              To start tracking your reading progress, simply go to the
              "Marketplace" tab, input the book you want to read, and click the
              "Move to Library" button. You can update your progress by entering
              the number of pages read and optionally adding notes to each log.
              ReadersGuild will automatically calculate your reading stats and
              update your profile.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography
              component="h3"
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              Can I join or create reading guilds?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body3"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              Absolutely! You can join existing public reading guilds (coming
              soon), your friend's guild, or create your own. To join your
              friend's guild, go to the "Guilds" tab, click the join guild
              button, and enter your friend's guild tag number. To create a new
              guild, click on "Create Guild," then fill in the provided fields.
              You can set guilds as public or private. You then will receive a
              guild tag number which you can use to let your friends join the
              guild.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography
              component="h3"
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              Is ReadersGuild available on mobile devices?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body3"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              ReadersGuild's web version is mobile-responsive, so you can access
              it from any browser of your choice. Meanwhile, we're developing
              the mobile app version for both iOS and Android.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography
              component="h3"
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              How does the TBR (To Be Read) list feature work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body3"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              The TBR list feature allows you to create and manage lists of
              books you want to read. You can add books to your TBR list,
              prioritize them, and even share your list with friends. To add a
              book to your TBR, simply search for it and click "Add to TBR."
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5d-content"
            id="panel5d-header"
          >
            <Typography
              component="h3"
              variant="body1"
              sx={{ fontWeight: "600" }}
            >
              What's included in the free version of ReadersGuild?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body3"
              gutterBottom
              sx={{ maxWidth: { sm: "100%", md: "70%" } }}
            >
              The free version of ReadersGuild includes basic book tracking for
              up to 10 books, the ability to join up to 2 reading guilds, access
              to community forums, and reading stats. You can also create one
              TBR list. For unlimited tracking, guild creation, and more
              features, consider upgrading to our "Premium" or "Pro" plans.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
