import React, {
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LeftProfile from "../../layouts/leftProfile";
import ContentGrid from "../../components/ContentGrid";
import { UserContext } from "../../context/UserContext";
import { getPublicReadingLogs } from "../../api/userApi";
import { removeReadingLogById, updateReadingLog } from "../../api/logApi";
import DialogEditNote from "../../components/DialogEditNote";
import { useSnackbar } from "../../context/SnackbarContext";
import CommunityCard from "./CommunityCard";

const Community = () => {
  const { userProfile } = useContext(UserContext);
  const [publicLogs, setPublicLogs] = useState([]);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      const logs = await getPublicReadingLogs();
      setPublicLogs(logs.data);
      console.log("public logs", logs);
    } catch (error) {
      console.error("Failed on fetching publicLogs", error);
    }
  };

  const handleRemoveLog = async (logId) => {
    try {
      await removeReadingLogById(logId);
      // optimistic update
      setPublicLogs((prevLogs) => prevLogs.filter((log) => log._id !== logId));
    } catch (error) {
      console.error("Error removing reading log:", error);
    }
  };

  const handleLogUpdate = async (logId, updatedNote, updatedPrivateSwitch) => {
    try {
      await updateReadingLog(logId, updatedNote, updatedPrivateSwitch);

      if (updatedPrivateSwitch) {
        setPublicLogs((prevLogs) =>
          prevLogs.filter((log) => log._id !== logId)
        );
      }
    } catch (error) {
      console.error("Error updating post:", error);
    }
  };

  return (
    <ContentGrid sx={{ gap: { xs: 2, md: 4 } }}>
      {!isMobileScreen && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gridColumn: "span 1",
            gap: 2,
          }}
        >
          <LeftProfile />
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gridColumn: { xs: "1 / -1", lg: "span 2" },
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: { xs: 2, md: 3 },
            width: "100%",
          }}
        >
          {publicLogs.map((log, index) => (
            <CommunityCard
              key={log._id}
              log={log}
              currentUserId={userProfile._id}
              onDelete={handleRemoveLog}
              onUpdateNote={handleLogUpdate}
              isMobileScreen={isMobileScreen}
              isSmallScreen={isSmallScreen}
            />
          ))}
        </Box>
      </Box>
    </ContentGrid>
  );
};

export default Community;
