import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LinearProgressBar from "../../components/LinearProgressBar";
import FlexBetween from "../../components/FlexBetween";
import { getUserLastReadingLog } from "../../api/userApi";
import dayjs from "dayjs";
import { useLoading } from "../../context/LoadingContext";
import { UserContext } from "../../context/UserContext";

const FinishedBookOverlay = ({
  bookTitle,
  onChooseNewBook,
  onChooseFromLibrary,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(5px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        padding: 2,
      }}
    >
      <Typography variant="h5" color="white" gutterBottom align="center">
        Congratulations!
      </Typography>
      <Typography variant="body1" color="white" gutterBottom align="center">
        You've finished "{bookTitle}"!
      </Typography>
      <Typography variant="body2" color="white" sx={{ mb: 4 }} align="center">
        What would you like to do next?
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button variant="contained" color="primary" onClick={onChooseNewBook}>
          Find New Book
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={onChooseFromLibrary}
          sx={{ color: "white", borderColor: "white" }}
        >
          Choose from Library
        </Button>
      </Box>
    </Box>
  );
};

const HomeBookProgressLargeWidget = () => {
  const [lastReadingLog, setLastReadingLog] = useState({});
  const [loading, setLoading] = useState(true);
  const [dataNotAvailable, setDataNotAvailable] = useState(true);
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 900px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:600px)");
  const { userProfile } = useContext(UserContext);

  useEffect(() => {
    fetchLastReadingLog();
  }, []);

  const fetchLastReadingLog = async () => {
    try {
      const result = await getUserLastReadingLog();
      if (result.success === true) {
        /* DATE */
        const dateObject = new Date(result.data.postDate);
        const options = { day: "2-digit", month: "long", year: "numeric" };
        const formattedDate = dateObject.toLocaleDateString("en-US", options);

        /* PERCENTAGE */
        const totalBookPage = result.data.book.pages;
        const lastPageRead = result.data.lastPageRead;
        const bookProgressPercentage = Math.round(
          (lastPageRead / totalBookPage) * 100
        );

        setLastReadingLog({
          ...result.data,
          postDate: formattedDate,
          bookProgressPercentage,
        });
        setDataNotAvailable(false);
      }
    } catch (error) {
      console.error("Error fetching last reading log:", error);
    } finally {
      setLoading(false); // Set loading to false after data has been fetched
    }
  };

  const handleUpdateBook = () => {
    setIsLoading(true);
    navigate(`/library/session/${lastReadingLog.book?._id}`);
  };
  const handleChooseNewBook = () => {
    navigate("/marketplace");
  };

  const handleChooseFromLibrary = () => {
    navigate("/library");
  };

  const endDate = dayjs(lastReadingLog.finishGoalDate);
  const today = dayjs();
  const daysLeft = endDate.diff(today, "day");
  const formattedDate = dayjs(lastReadingLog.finishGoalDate).format(
    "MMMM DD, YYYY"
  );

  const defaultCover = "/assets/book-sample.jpg";

  return loading ? (
    <p>Loading...</p>
  ) : (
    <>
      {isNonMobileScreens ? (
        <Card sx={{ position: "relative" }}>
          {userProfile.userAmtOfBooks === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "full",
                p: 2,
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.main,
                }}
                onClick={() => navigate("/marketplace")}
              >
                Add Your First Book
              </Button>
            </Box>
          ) : !lastReadingLog ||
            Object.keys(lastReadingLog).length === 0 ||
            lastReadingLog.error ||
            lastReadingLog === undefined ||
            lastReadingLog === null ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "full",
                p: 2,
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.main,
                }}
                onClick={() => navigate("/library")}
              >
                Start Reading Your First Book
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                p: "16px",
                backdropFilter: "blur(10px)",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
                  <Box
                    sx={{
                      boxShadow: 3,
                      width: 108,
                      minWidth: 118,
                      height: "100%",
                      borderRadius: "6%",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={
                        lastReadingLog.book?.cover?.large ||
                        lastReadingLog.book?.cover?.medium ||
                        lastReadingLog.book?.cover?.small ||
                        defaultCover
                      }
                      alt="book"
                      sx={{ cursor: "pointer", borderRadius: "6%" }}
                      onClick={() =>
                        navigate(`/library/session/${lastReadingLog.book?._id}`)
                      }
                    />
                  </Box>
                  <FlexBetween sx={{ flexDirection: "column" }}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >
                      <Box>
                        <Typography variant="body1" sx={{ fontWeight: "600" }}>
                          Progress
                        </Typography>
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              minWidth: "120px",
                              gap: 1,
                            }}
                          >
                            <LinearProgressBar
                              percentage={
                                lastReadingLog?.bookProgressPercentage
                              }
                            />
                            <Typography>
                              {lastReadingLog?.bookProgressPercentage}%
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        width: "100%",
                        gap: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!lastReadingLog.book ? (
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => navigate("/marketplace")}
                        >
                          Choose new book
                        </Button>
                      ) : (
                        <Button
                          size="small"
                          variant="outlined"
                          color={
                            lastReadingLog?.bookProgressPercentage < 100
                              ? "primary"
                              : "neutral"
                          }
                          onClick={handleUpdateBook}
                        >
                          {lastReadingLog?.bookProgressPercentage < 100
                            ? "Update Book"
                            : "Open Journal"}
                        </Button>
                      )}
                    </Box>
                  </FlexBetween>
                </Box>

                <Divider orientation="vertical" variant="full" flexItem />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <Box>
                    <FlexBetween sx={{ alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        noWrap
                        style={{
                          maxWidth: "280px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {lastReadingLog.book?.title}
                      </Typography>
                      <Typography variant="overline">Recent Log</Typography>
                    </FlexBetween>
                    <Typography variant="caption">
                      by {lastReadingLog.book?.authors}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        flex: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: theme.palette.neutral.medium,
                        }}
                      >
                        Log Date:
                      </Typography>
                      <Typography variant="body2">
                        {lastReadingLog?.postDate}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        flex: 1,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: theme.palette.neutral.medium,
                        }}
                      >
                        Page Read:
                      </Typography>
                      <Typography variant="body2">
                        {lastReadingLog?.amountPageRead}
                      </Typography>
                    </Box>
                    {lastReadingLog?.finishGoalDate && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            flex: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.neutral.main,
                            }}
                          >
                            Finish Goal Date:
                          </Typography>
                          <Typography variant="body2">
                            {formattedDate}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 1,
                            flex: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              color: theme.palette.neutral.main,
                            }}
                          >
                            Finishing Book Deadline:
                          </Typography>
                          {!(lastReadingLog?.bookProgressPercentage >= 100) ? (
                            <Typography
                              variant="subtitle2"
                              sx={{
                                color:
                                  daysLeft <= 8 && theme.palette.error.main,
                              }}
                            >
                              {daysLeft} days
                            </Typography>
                          ) : (
                            <Typography variant="subtitle2" color="primary">
                              Book Finished
                            </Typography>
                          )}
                        </Box>
                      </>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 1,
                        flex: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme.palette.neutral.medium,
                        }}
                        variant="subtitle2"
                      >
                        Log Type:
                      </Typography>
                      <Typography variant="body2">
                        {lastReadingLog?.privateSwitch ? "Private" : "Public"}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {lastReadingLog?.bookProgressPercentage >= 100 && (
                <FinishedBookOverlay
                  bookTitle={lastReadingLog.book?.title}
                  onChooseNewBook={handleChooseNewBook}
                  onChooseFromLibrary={handleChooseFromLibrary}
                />
              )}
              {/* )} */}
            </Box>
          )}
        </Card>
      ) : (
        <Card>
          {userProfile.userAmtOfBooks === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "full",
                p: 2,
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.main,
                }}
                onClick={() => navigate("/marketplace")}
              >
                Add Your First Book
              </Button>
            </Box>
          ) : !lastReadingLog ||
            Object.keys(lastReadingLog).length === 0 ||
            lastReadingLog.error ||
            lastReadingLog === undefined ||
            lastReadingLog === null ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "full",
                p: 2,
              }}
            >
              <Button
                size="small"
                variant="contained"
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.primary.main,
                }}
                onClick={() => navigate("/library")}
              >
                Start Reading Your First Book
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                p: "16px",
                backdropFilter: "blur(10px)",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: 1.5 }}>
                <Box
                  sx={{
                    boxShadow: 3,
                    width: 100,
                    minWidth: 106,
                    height: "100%",
                    borderRadius: "6%",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={
                      lastReadingLog.book?.cover?.large ||
                      lastReadingLog.book?.cover?.medium ||
                      lastReadingLog.book?.cover?.small ||
                      defaultCover
                    }
                    alt="book"
                    sx={{ cursor: "pointer", borderRadius: "6%" }}
                    onClick={() =>
                      navigate(`/library/session/${lastReadingLog.book?._id}`)
                    }
                  />
                </Box>
                <FlexBetween sx={{ width: "100%", flexDirection: "column" }}>
                  <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 0 }}
                    >
                      <FlexBetween sx={{ alignItems: "center" }}>
                        {!isExtraSmallScreen ? (
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "600" }}
                          >
                            {lastReadingLog.book?.title}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            noWrap
                            style={{
                              maxWidth: "180px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {lastReadingLog.book?.title}
                          </Typography>
                        )}
                      </FlexBetween>
                      <Typography variant="caption">
                        by {lastReadingLog.book?.authors}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "600", whiteSpace: "nowrap" }}
                      >
                        Progress:
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                          flexGrow: 1,
                        }}
                      >
                        <LinearProgressBar
                          percentage={lastReadingLog?.bookProgressPercentage}
                        />
                        <Typography
                          sx={{ minWidth: "fit-content", whiteSpace: "nowrap" }}
                        >
                          {lastReadingLog?.bookProgressPercentage}%
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          flex: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: theme.palette.neutral.medium,
                          }}
                        >
                          Log Date:
                        </Typography>
                        <Typography variant="body2">
                          {lastReadingLog?.postDate}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 1,
                          flex: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 600,
                            color: theme.palette.neutral.main,
                          }}
                        >
                          Page Read:
                        </Typography>
                        <Typography variant="body2">
                          {lastReadingLog?.amountPageRead}
                        </Typography>
                      </Box>
                    </Box>
                    {!lastReadingLog.book ? (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                        }}
                        onClick={() => navigate("/marketplace")}
                      >
                        Choose new book
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        variant="contained"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                        }}
                        onClick={handleUpdateBook}
                      >
                        {lastReadingLog?.bookProgressPercentage < 100
                          ? "Update Book"
                          : "Open Journal"}
                      </Button>
                    )}
                  </Box>

                  {/* {lastReadingLog?.bookProgressPercentage >= 100 && (
                  <Button
                    size="small"
                    variant="contained"
                    sx={{ backgroundColor: theme.palette.primary.main }}
                    onClick={() => navigate("/library")}
                  >
                    Open Library
                  </Button>
                )} */}
                </FlexBetween>
              </Box>
            </Box>
          )}
          {lastReadingLog?.bookProgressPercentage >= 100 && (
            <FinishedBookOverlay
              bookTitle={lastReadingLog.book?.title}
              onChooseNewBook={handleChooseNewBook}
              onChooseFromLibrary={handleChooseFromLibrary}
            />
          )}
        </Card>
      )}
    </>
  );
};

export default HomeBookProgressLargeWidget;
