import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const likeDiscussion = async (roomId, discussionId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}/discussions/${discussionId}/like`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error liking/unliking discussion:", error);
    throw error;
  }
};

export const createNewRoom = async (roomData) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/rooms/create`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({
        name: roomData.name,
        isPublic: roomData.isPublic,
        roomPassword: roomData.roomPassword,
        maxMembers: roomData.maxMembers,
        currentBook: roomData.currentBook,
      }),
    });
    return response.data;
  } catch (error) {
    console.error("Error creating new room:", error);
    throw error;
  }
};

export const editRoomDiscussion = async (roomId, discussionId, updatedNote) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}/discussions/${discussionId}`,
      method: "PATCH",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ readingNotes: updatedNote }),
    });
    return response.data;
  } catch (error) {
    console.error("Error editing discussion:", error);
    throw error;
  }
};

export const getUsersRooms = async () => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/rooms/user/all`,
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user's rooms:", error);
    throw error;
  }
};

export const addRoomDiscussion = async (roomId, discussionData) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}/discussions`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(discussionData),
    });
    return response.data;
  } catch (error) {
    console.error("Error posting discussion:", error);
    throw error;
  }
};

export const checkRoom = async (roomTag) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/check`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ roomTag }),
    });
    return response.data;
  } catch (error) {
    console.error("Error checking room:", error);
    throw error;
  }
};

export const joinRoom = async (roomTag, roomPassword) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/join`,
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ roomTag, roomPassword }),
    });
    return response.data;
  } catch (error) {
    console.error("Error joining room:", error);
    throw error;
  }
};

// In your API file (e.g., roomApi.js)
export const removeRoom = async (roomId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}`,
      method: "DELETE",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error removing room:", error);
    throw error;
  }
};

export const updateRoomBook = async (roomId, newBookId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}/book`,
      method: "PUT",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ newBookId }),
    });
    return response.data;
  } catch (error) {
    console.error("Error updating room book:", error);
    throw error;
  }
};

// In your API file (e.g., roomApi.js)
export const removeDiscussion = async (roomId, discussionId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}/discussions/${discussionId}`,
      method: "DELETE",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error removing discussion:", error);
    throw error;
  }
};

export const removeMemberFromRoom = async (roomId, memberId) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/rooms/${roomId}/members/${memberId}`,
      method: "DELETE",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error removing member from room:", error);
    throw error;
  }
};
