import React from "react";
import {
  useTheme,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const AnnouncementBar = ({ message, ctaText, ctaLink, onClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleCtaClick = () => {
    onClose();
    navigate(ctaLink);
  };
  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.palette.primary.extraDark }}
    >
      <Toolbar sx={{ justifyContent: "center" }}>
        <Typography variant="body1">{message}</Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCtaClick}
            sx={{
              mr: 2,
              ml: 1,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
              },
            }}
          >
            {ctaText}
          </Button>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AnnouncementBar;
