import { useContext, useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  TextField,
  DialogActions,
  Avatar,
  Typography,
  useTheme,
  Stack,
  useMediaQuery,
  IconButton,
  useStepContext,
  RadioGroup,
  CircularProgress,
  FormControlLabel,
  Radio,
  FormGroup,
  Checkbox,
  FormLabel,
  Chip,
} from "@mui/material";
import EditButton from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import FlexBetween from "../../components/FlexBetween";
import StackCenteredBox from "../../components/StackCenteredBox";
import { UserContext } from "../../context/UserContext";
import FormDialog from "../../components/FormDialog";
import { postUserLocation } from "../../api/userApi";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import Dropzone from "react-dropzone";
import { editUserProfile } from "../../api/userApi";
import CardContentPadding from "../../components/CardContentPadding";
import CloseIcon from "@mui/icons-material/Close"; // import NotificationCard from "../../components/NotificationCard";
import { fontSize } from "@mui/system";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

export const EditProfile = ({
  open,
  onClose,
  initialValues,
  onUpdateProfile,
  check,
}) => {
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const [currentProfilePicture, setCurrentProfilePicture] = useState(null);

  useEffect(() => {
    if (initialValues.avatar) {
      setCurrentProfilePicture(initialValues.avatar);
    }
  }, [initialValues.avatar, currentProfilePicture]);

  console.log("check", currentProfilePicture);

  const handleEditProfileSubmit = async (values) => {
    const formData = new FormData();
    for (let value in values) {
      if (value === "readingPreferences") {
        formData.append(value, values[value].join(","));
      } else {
        formData.append(value, values[value]);
      }
    }
    if (values.picture) {
      formData.append("avatar", values.picture.name);
    }
    const result = await editUserProfile(formData);
    if (result.success === true) {
      onUpdateProfile(result.data);
      localStorage.setItem("showMessage", "updateProfile");
      updateUserProfile(result.data);
      // setCurrentProfilePicture(values.picture.name);
      localStorage.setItem("userData", JSON.stringify(result.data));
      onClose();
    }
  };

  const handleCloseDialog = () => {
    onClose();
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Profile</Typography>
          <IconButton onClick={handleCloseDialog} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Formik
          initialValues={{
            ...initialValues,
            readingPreferences: initialValues.readingPreferences || [],
          }}
          onSubmit={handleEditProfileSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box
                display="flex"
                flexDirection="column"
                gap="20px"
                sx={{ mt: 3 }}
              >
                {/* Circular Profile Picture Dropzone */}
                <Box display="flex" justifyContent="center" mb={2}>
                  <Dropzone
                    acceptedFiles=".jpg,.jpeg,.png"
                    multiple={false}
                    onDrop={(acceptedFiles) => {
                      if (acceptedFiles.length > 0) {
                        setFieldValue("picture", acceptedFiles[0]);
                        setCurrentProfilePicture(
                          URL.createObjectURL(acceptedFiles[0])
                        );
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Box
                        {...getRootProps()}
                        sx={{
                          width: 120,
                          height: 120,
                          borderRadius: "50%",
                          border: `2px dashed gray`,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <input {...getInputProps()} />
                        <Avatar
                          src={
                            currentProfilePicture === null
                              ? userProfile.avatar
                              : currentProfilePicture
                          }
                          sx={{ width: "100%", height: "100%" }}
                        >
                          {(currentProfilePicture === null ||
                            !userProfile.avatar) && (
                            <Typography>Add Picture</Typography>
                          )}
                        </Avatar>
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0,0,0,0.3)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            opacity: 0,
                            transition: "opacity 0.3s",
                            "&:hover": { opacity: 1 },
                          }}
                        >
                          <EditOutlinedIcon sx={{ color: "white" }} />
                        </Box>
                      </Box>
                    )}
                  </Dropzone>
                </Box>

                <TextField
                  label="Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  name="name"
                  error={touched.name && errors.name}
                  helperText={touched.name && errors.name}
                  fullWidth
                />
                <TextField
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  type="email"
                  name="email"
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  fullWidth
                />
                <TextField
                  label="Location"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                  name="location"
                  error={touched.location && errors.location}
                  helperText={touched.location && errors.location}
                  fullWidth
                />

                <FormGroup>
                  <FormLabel component="legend">
                    Reading Preference Options
                  </FormLabel>
                  {["kindle", "e-book", "physicalBook"].map(
                    (preference, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={values.readingPreferences.includes(
                              preference
                            )}
                            onChange={(event) => {
                              const checked = event.target.checked;
                              const newPreferences = checked
                                ? [...values.readingPreferences, preference]
                                : values.readingPreferences.filter(
                                    (p) => p !== preference
                                  );
                              setFieldValue(
                                "readingPreferences",
                                newPreferences
                              );
                            }}
                          />
                        }
                        label={capitalizeFirstLetter(
                          preference.replace("-", " ")
                        )}
                      />
                    )
                  )}
                </FormGroup>
              </Box>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const ProfileWidget = () => {
  const { userProfile } = useContext(UserContext);
  const [userProfileState, setUserProfileState] = useState(userProfile);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (userProfile) {
      setUserProfileState(userProfile);
    }
  }, [userProfile]);

  console.log("test", userProfileState);

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSaveLocation = async (location) => {
    const result = await postUserLocation(location);
  };

  const handleProfileUpdate = (updatedProfile) => {
    setUserProfileState(updatedProfile);
  };

  return (
    <>
      {isNonMobileScreens ? (
        <Box>
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  // justifyContent: "flex-end",
                  alignItems: "center",
                  width: "full",
                }}
              >
                {userProfileState.currentPlan === "premium" && (
                  <Chip
                    variant="contained"
                    label="Premium"
                    size="small"
                    color="primary"
                    icon={<AutoAwesomeIcon />}
                  />
                )}
                {/* <Typography variant="body3" sx={{ fontWeight: "600" }}>
                  #{userProfileState.uniqueTag}
                </Typography> */}
                <IconButton variant="small" onClick={handleOpenDialog}>
                  <EditButton variant="small" />
                </IconButton>
              </Box>
              <Stack
                spacing={3}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Avatar
                    alt={userProfileState.name}
                    src={userProfileState.avatar}
                    sx={{ width: 112, height: 112 }}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                    {userProfileState.name}
                  </Typography>
                  {userProfileState.location && (
                    <Typography variant="body1">
                      {userProfileState.location}
                    </Typography>
                  )}
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.neutral.mediumLight }}
                  >
                    Member since {userProfileState.registrationDate}
                  </Typography>
                </Box>
                <Box sx={{ width: "70%" }}>
                  <FlexBetween>
                    <StackCenteredBox>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.neutral.medium,
                        }}
                      >
                        Book Rated
                      </Typography>
                      <Typography variant="body3">
                        {userProfileState.amountOfBookRated}
                      </Typography>
                    </StackCenteredBox>
                    <StackCenteredBox>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.neutral.medium,
                        }}
                      >
                        Book Owned
                      </Typography>
                      <Typography variant="body3">
                        {userProfileState.userAmtOfBooks}
                      </Typography>
                    </StackCenteredBox>
                  </FlexBetween>
                </Box>
              </Stack>
            </CardContent>
          </Card>
          <EditProfile
            open={isDialogOpen}
            onClose={handleCloseDialog}
            initialValues={{
              name: userProfileState.name,
              email: userProfileState.email,
              location: userProfileState.location,
              picture: null,
              readingPreferences: userProfileState.readingPreferences || [],
              avatar: userProfileState.avatar, // Explicitly include the avatar
              check: userProfileState,
            }}
            onUpdateProfile={handleProfileUpdate}
          />
        </Box>
      ) : (
        <Box>
          <Card>
            <CardContentPadding
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton variant="small" onClick={handleOpenDialog}>
                  <EditButton variant="small" />
                </IconButton>
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 3,
                }}
              >
                <Box>
                  <Avatar
                    alt={userProfileState.name}
                    src={userProfileState.avatar}
                    sx={{ width: 68, height: 68 }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    width: "100%",
                  }}
                >
                  <FlexBetween sx={{ width: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h6" sx={{ whiteSpace: "nowrap" }}>
                        {userProfileState.name}
                      </Typography>
                      {userProfileState.currentPlan === "premium" && (
                        <Chip
                          variant="contained"
                          label="Premium"
                          size="small"
                          color="primary"
                          icon={<AutoAwesomeIcon />}
                        />
                      )}
                    </Box>
                    <IconButton
                      variant="small"
                      sx={{ p: 0 }}
                      onClick={handleOpenDialog}
                    >
                      <EditButton variant="small" sx={{ p: 0 }} />
                    </IconButton>
                  </FlexBetween>
                  {/* <Typography variant="body1">
                    {userProfileState.location ? (
                      userProfileState.location
                    ) : (
                      <FormDialog
                        buttonText="Add Your Location"
                        title="Add User Location"
                        name="location"
                        label="Add Location"
                        saveForm={handleSaveLocation}
                        sx={{ fontSize: "12px" }}
                      />
                    )}
                  </Typography> */}
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.neutral.mediumLight }}
                  >
                    Member since {userProfileState.registrationDate}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        {userProfileState.amountOfBookRated}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>
                        book(s) rated,
                      </Typography>
                    </Box>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        {userProfileState.userAmtOfBooks}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ fontSize: "12px" }}>
                        book(s) owned
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </CardContentPadding>
          </Card>
          <EditProfile
            open={isDialogOpen}
            onClose={handleCloseDialog}
            initialValues={{
              name: userProfileState.name,
              email: userProfileState.email,
              location: userProfileState.location,
              picture: null,
              readingPreferences: userProfileState.readingPreferences || [],
              avatar: userProfileState.avatar, // Explicitly include the avatar
              check: userProfileState,
            }}
            onUpdateProfile={handleProfileUpdate}
          />
        </Box>
      )}
    </>
  );
};

export default ProfileWidget;
