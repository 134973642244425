import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const postReadingLog = async (values, bookId, bookFinished) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const {
      postDate,
      lastPageRead,
      startPageRead,
      readingNotes,
      privateSwitch,
    } = values;
    const response = await axios({
      url: `${BASE_URL}/logs/` + bookId,
      method: "POST",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({
        postDate,
        lastPageRead,
        readingNotes,
        privateSwitch,
        startPageRead,
        bookFinished,
        userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }),
    });
    return response;
  } catch (error) {
    console.error("Error creating new reading log:", error);
    throw error;
  }
};

// In your API file (e.g., logApi.js)

export const updateReadingLog = async (
  logId,
  updatedNote,
  updatedPrivateSwitch
) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/logs/${logId}/note`,
      method: "PATCH",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ readingNotes: updatedNote, updatedPrivateSwitch }),
    });
    return response.data;
  } catch (error) {
    console.error("Error updating reading log note:", error);
    throw error;
  }
};

export const removeReadingLogById = async (logId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/logs/` + logId,
      method: "DELETE",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response;
  } catch (error) {
    console.error("Error removing reading log", error);
    throw error;
  }
};

// export const loginUser = async (values) => {
//   const { username, password } = values;
//   const response = await axios({
//     url: `${BASE_URL}/login`,
//     method: "POST",
//      headers: {
//   Authorization: `Bearer ${token}`, // Include token in Authorization header
//   Accept: "application/json",
//   "Content-Type": "application/json;charset=UTF-8",
// },
//     data: JSON.stringify({ username, password }),
//   });
//   return response;
// };
