import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { formatDistanceToNow } from "date-fns";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import {
  addComment,
  getComments,
  removeComment,
  likeComment,
  editComment,
} from "../../api/commentApi";

const CommentSection = ({ discussionId, roomId, currentUserId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuCommentId, setMenuCommentId] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedCommentContent, setEditedCommentContent] = useState("");

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const result = await getComments(discussionId, roomId);
      setComments(result.comments);
    } catch (error) {
      console.error("Failed to fetch comments:", error);
    }
  };

  const handleAddComment = async () => {
    try {
      const result = await addComment(discussionId, newComment, roomId);
      setComments([result.comment, ...comments]);
      setNewComment("");
    } catch (error) {
      console.error("Failed to add comment:", error);
    }
  };

  const handleRemoveComment = async (commentId) => {
    try {
      await removeComment(commentId);
      setComments(comments.filter((comment) => comment._id !== commentId));
      handleCloseMenu();
    } catch (error) {
      console.error("Failed to remove comment:", error);
    }
  };

  const handleLikeComment = async (commentId) => {
    try {
      const result = await likeComment(commentId);
      setComments(
        comments.map((comment) =>
          comment._id === commentId
            ? { ...comment, likes: result.likes }
            : comment
        )
      );
    } catch (error) {
      console.error("Failed to like/unlike comment:", error);
    }
  };

  const handleEditCommentStart = (commentId) => {
    const commentToEdit = comments.find((c) => c._id === commentId);
    setEditingCommentId(commentId);
    setEditedCommentContent(commentToEdit.content);
    handleCloseMenu();
  };

  const handleEditCommentSave = async (commentId) => {
    try {
      const result = await editComment(commentId, editedCommentContent);
      setComments(
        comments.map((comment) =>
          comment._id === commentId
            ? { ...comment, content: result.comment.content }
            : comment
        )
      );
      setEditingCommentId(null);
      setEditedCommentContent("");
    } catch (error) {
      console.error("Failed to edit comment:", error);
    }
  };

  const handleOpenMenu = (event, commentId) => {
    setAnchorEl(event.currentTarget);
    setMenuCommentId(commentId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setMenuCommentId(null);
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Add a comment..."
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={handleAddComment}
              disabled={!newComment.trim()}
            >
              <CheckIcon />
            </IconButton>
          ),
        }}
      />

      <Box sx={{ mt: 3 }}>
        {comments.map((comment) => (
          <Box
            key={comment._id}
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ flex: 1, mr: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {comment.user.name}
              </Typography>
              {editingCommentId === comment._id ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={editedCommentContent}
                  onChange={(e) => setEditedCommentContent(e.target.value)}
                  multiline
                  rows={2}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => handleEditCommentSave(comment._id)}
                        disabled={!editedCommentContent.trim()}
                      >
                        <CheckIcon />
                      </IconButton>
                    ),
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleEditCommentSave(comment._id);
                    }
                  }}
                />
              ) : (
                <Typography variant="body2">{comment.content}</Typography>
              )}
              <Typography variant="caption" color="text.secondary">
                {formatDistanceToNow(new Date(comment.createdAt), {
                  addSuffix: true,
                })}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => handleLikeComment(comment._id)}
                color={
                  comment.likes.includes(currentUserId) ? "primary" : "default"
                }
              >
                {comment.likes.includes(currentUserId) ? (
                  <FavoriteIcon />
                ) : (
                  <FavoriteBorderIcon />
                )}
              </IconButton>
              {comment.likes.length > 0 && (
                <Typography variant="body2" component="span">
                  {comment.likes.length}
                </Typography>
              )}
              {comment.user._id === currentUserId && (
                <IconButton onClick={(e) => handleOpenMenu(e, comment._id)}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </Box>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && menuCommentId === comment._id}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={() => handleEditCommentStart(comment._id)}>
                <EditIcon sx={{ mr: 1 }} /> Edit
              </MenuItem>
              <MenuItem onClick={() => handleRemoveComment(comment._id)}>
                <DeleteIcon sx={{ mr: 1 }} /> Delete
              </MenuItem>
            </Menu>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CommentSection;
