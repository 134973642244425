import React from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Card,
  useMediaQuery,
  CardContent,
} from "@mui/material";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import GroupIcon from "@mui/icons-material/Group";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import CreateIcon from "@mui/icons-material/Create";

const benefits = [
  {
    icon: <TrackChangesIcon fontSize="large" />,
    title: "Stay Focused",
    description:
      "Set reading goals and track your progress to build lasting reading habits.",
  },
  {
    icon: <CreateIcon fontSize="large" />,
    title: "Keep a Reading Journal",
    description:
      "Take notes, record thoughts, and revisit your literary journey anytime.",
  },
  {
    icon: <TrendingUpIcon fontSize="large" />,
    title: "Gain Insights",
    description:
      "Analyze your reading patterns and discover new aspects of your literary preferences.",
  },
  {
    icon: <GroupIcon fontSize="large" />,
    title: "Connect with Readers",
    description:
      "Join reading guilds to share thoughts, recommendations, and foster meaningful discussions.",
  },
  {
    icon: <MenuBookIcon fontSize="large" />,
    title: "Expand Your Library",
    description:
      "Curate your To-Be-Read list and discover new titles through community recommendations.",
  },
];

export default function Benefits() {
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");

  return (
    <Container
      id="benefits"
      sx={{
        pt: { xs: 2, sm: 6 },
        pb: { xs: 4, sm: 8 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          variant={isSuperSmallScreen ? "h6" : "h4"}
          color="text.primary"
        >
          {" "}
          Why Choose ReadersGuild
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ fontWeight: "400", mt: isSuperSmallScreen ? 0 : 2 }}
        >
          Enhance your reading experience with features designed to help you
          read more, understand deeper, and connect with fellow book lovers.
        </Typography>
      </Box>
      <Grid container spacing={3} justifyContent="center">
        {benefits.map((benefit, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
              <CardContent sx={{ flexGrow: 1, textAlign: "center" }}>
                <Box sx={{ mb: 2, color: "primary.main" }}>{benefit.icon}</Box>
                <Typography gutterBottom variant="h5" component="h3">
                  {benefit.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {benefit.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
