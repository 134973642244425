import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme, alpha, useMediaQuery } from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import BarChartIcon from "@mui/icons-material/BarChart";
import GroupIcon from "@mui/icons-material/Group";

const items = [
  {
    icon: <MenuBookIcon />,
    title: "Log & Journaling System",
    description:
      "Easily log your reading sessions with pages read, date, and personal notes.",
    video:
      "https://res.cloudinary.com/dd5yl51lm/video/upload/v1723500892/RG-demo-1_hdpnta.mp4",
  },
  {
    icon: <BarChartIcon />,
    title: "Genre Chart and Analytics",
    description:
      "Visualize your reading genres, daily reading habits, and other insightful analytics.",
    video:
      "https://res.cloudinary.com/dd5yl51lm/video/upload/v1723500886/RG-demo-3_rzx28w.mp4",
  },
  {
    icon: <GroupIcon />,
    title: "Group & Solo Tracker",
    description:
      "Read solo or create and join reading groups, share progress, schedule meetings (coming soon), and compare notes.",
    video:
      "https://res.cloudinary.com/dd5yl51lm/video/upload/v1723500888/RG-demo-2_iagw1r.mp4",
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");
  const theme = useTheme();

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  return (
    <Container
      id="features"
      sx={{ pt: { xs: 2, sm: 6 }, pb: { xs: 4, sm: 8 } }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          textAlign: { sm: "left", md: "center" },
          alignItems: { sm: "start", md: "center" },
          mb: { xs: 2, sm: 4 },
        }}
      >
        <Typography
          variant={isSuperSmallScreen ? "h6" : "h4"}
          color="text.primary"
        >
          What we have for you
        </Typography>
        <Typography
          variant="body1"
          color="text.secondary"
          sx={{ width: { sm: "100%", md: "60%" } }}
        >
          Whether you're reading alone or with a group, we'll help you track
          progress, journal thoughts, and build lasting reading habits.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Grid
            container
            item
            gap={1}
            sx={{ display: { xs: "auto", sm: "none" } }}
          >
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  borderColor:
                    selectedItemIndex === index ? "neutral.light" : "",
                  backgroundColor:
                    selectedItemIndex === index ? "neutral.main" : "",
                  "& .MuiChip-label": {
                    color: selectedItemIndex === index ? "#fff" : "",
                  },
                }}
              />
            ))}
          </Grid>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={0.5}
            useFlexGap
            sx={{ width: "100%", display: { xs: "none", sm: "flex" } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Button
                key={index}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: "fit-content",
                  width: "100%",
                  background: "none",
                  backgroundColor:
                    selectedItemIndex === index
                      ? alpha(theme.palette.primary.main, 0.1)
                      : "transparent",
                  border: 1,
                  borderColor:
                    selectedItemIndex === index ? "primary.main" : "grey.300",
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.05),
                  },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    textAlign: "left",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: { md: "center" },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color:
                        selectedItemIndex === index
                          ? "primary.main"
                          : "text.secondary",
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      color="text.primary"
                      variant="subtitle1"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ mt: 0.5 }}
                    >
                      {description}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: "flex", width: "100%" }}>
          <Card
            sx={{
              width: "100%",
              aspectRatio: "7/5",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <video
                key={selectedItemIndex}
                autoPlay
                loop
                muted
                playsInline
                style={{ width: "102%", height: "102%", objectFit: "cover" }}
              >
                <source src={items[selectedItemIndex].video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
