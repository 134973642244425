import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Chip,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  DialogContentText,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { UserContext } from "../../context/UserContext";
import { useSnackbar } from "../../context/SnackbarContext";
import { cancelUserSubscription } from "../../api/stripeApi";
import { useSocket } from "../../context/SocketContext"; // Make sure to import this
import { getUserProfileData } from "../../api/userApi";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const plans = [
  {
    planName: "Basic",
    features: [
      "Track up to 10 books",
      "Join 2 reading groups",
      "Reading stats and charts",
      "Create a TBR list",
      "Access to community forums",
    ],
    link: "",
    priceId: "",
    price: "0",
    duration: "month",
    billed: "Free forever",
    buttonText: "Start Reading",
    buttonVariant: "outlined",
    plan: "free",
  },
  {
    planName: "Premium",
    subheader: "Most Popular",
    features: [
      "Unlimited book tracking",
      "Join unlimited reading groups",
      "Create small guilds (max 10 members)",
      "Guild forum, poll, and scheduling system",
      "Ad-free experience",
      "Early access to new features",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_9AQ4hy42wevUeLSaEL"
        : "https://buy.stripe.com/9AQ6pB5JR4Gx1vG5kk",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PnUzmIlmmGEqLN75SJgQ5lO"
        : "price_1PoZ2EIlmmGEqLN73yv7J6md",
    price: 4.99,
    duration: "month",
    billed: "Billed Monthly",
    buttonText: "Upgrade Now",
    buttonVariant: "contained",
    plan: "premium",
  },
  {
    planName: "Premium",
    subheader: "Most Popular",
    features: [
      "Unlimited book tracking",
      "Join unlimited reading groups",
      "Create small guilds (max 10 members)",
      "Guild forum, poll, and scheduling system",
      "Ad-free experience",
      "Early access to new features",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_9AQ4hy42wevUeLSaEL"
        : "https://buy.stripe.com/4gwaFR0pxgpf7U45kl",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PnUzmIlmmGEqLN75SJgQ5lO"
        : "price_1PoZ2EIlmmGEqLN7frzVFxM3",
    price: 49.99,
    duration: "year",
    billed: "Monthly Billing Price: $59.88 per year",
    buttonText: "Upgrade Now",
    buttonVariant: "contained",
    plan: "premium",
  },
  {
    planName: "Pro",
    features: [
      "All Premium features",
      "Create large guilds (max 250 members)",
      "Guild chat box",
      "Priority customer support",
      "Custom reading challenges",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_9AQ6pGgPi5Zo0V228g"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PnV4AIlmmGEqLN7cUXU3mb6"
        : "",
    price: 9.99,
    duration: "month",
    billed: "Billed Monthly",
    buttonText: "Upcoming",
    // buttonText: "Get Pro",
    buttonVariant: "outlined",
    plan: "pro",
  },
  {
    planName: "Pro",
    features: [
      "All Premium features",
      "Create large guilds (max 25 members)",
      "Guild chat box",
      "Priority customer support",
      "Custom reading challenges",
    ],
    link:
      process.env.NODE_ENV === "development"
        ? "https://buy.stripe.com/test_28o9BS8iMevU6fm7su"
        : "",
    priceId:
      process.env.NODE_ENV === "development"
        ? "price_1PHjqNIlmmGEqLN75DofuWhW"
        : "",
    price: 99.99,
    duration: "year",
    billed: "Monthly Billing Price: $119.88 per year",
    buttonText: "Upcoming",
    // buttonText: "Get Pro",
    buttonVariant: "outlined",
    plan: "pro",
  },
];

export default function Pricing() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSuperSmallScreen = useMediaQuery("(max-width: 450px)");
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const { userProfile, updateUserProfile } = useContext(UserContext);
  const snackbar = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const socket = useSocket();
  const [isAnnual, setIsAnnual] = useState(false);

  const formatDate = (date) => {
    return dayjs(date).format("MMMM Do, YYYY");
  };

  useEffect(() => {
    if (socket) {
      socket.on("subscriptionPlanUpdated", async () => {
        try {
          await updateUserSubscriptionPlanData();
        } catch (error) {
          console.error("Failed to fetch updated user profile:", error);
        }
      });

      return () => {
        socket.off("subscriptionPlanUpdated");
      };
    }
  }, [socket]);

  const updateUserSubscriptionPlanData = async () => {
    try {
      const result = await getUserProfileData();
      updateUserProfile(result.data);
      localStorage.setItem("userData", JSON.stringify(result.data));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleSubscribe = (link, userEmail) => {
    const subscriptionLink = `${link}?prefilled_email=${encodeURIComponent(
      userEmail
    )}`;
    window.open(subscriptionLink, "_blank", "noopener,noreferrer");
  };

  const handleCancelSubscription = async () => {
    try {
      setIsLoading(true);
      const result = await cancelUserSubscription();
      const endDate = new Date(result.subscriptionEndDate).toLocaleDateString();
      const message = `Your subscription has been successfully cancelled. You will have access to premium features until ${endDate}. Thank you for using our service!`;
      snackbar(message);
      await updateUserSubscriptionPlanData();
    } catch (error) {
      console.error(error);
      snackbar(
        "An error occurred while cancelling your subscription. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isSubscriptionActive = () => {
    return userProfile.subscriptionStatus === "active";
  };

  const isSubscriptionBeingCanceled = () => {
    return userProfile.cancellationDate && isSubscriptionActive();
  };

  const getButtonText = (plan) => {
    if (isLoading) return "Processing...";
    if (userProfile.currentPlan === plan.plan) {
      if (plan.plan === "free") return "Current Plan";
      if (isSubscriptionBeingCanceled())
        return "Cancelled - Access Until End of Term";
      return "Cancel Subscription";
    }
    if (plan.plan === "free") return "Downgrade to Free";
    return plan.buttonText;
  };

  const isButtonDisabled = (plan) => {
    return (
      plan.plan === "pro" ||
      (userProfile.currentPlan === plan.plan &&
        (plan.plan === "free" || isSubscriptionBeingCanceled())) ||
      isLoading
    );
  };

  const filteredPlans = plans.filter(
    (plan) =>
      plan.duration === (isAnnual ? "year" : "month") || plan.plan === "free"
  );
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 2, sm: 4 },
        pb: { xs: 4, sm: 6 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          variant={isSuperSmallScreen ? "h5" : "h3"}
          color="text.primary"
          sx={{
            fontWeight: 900,
            lineHeight: 1.2,
          }}
        >
          Upgrade your reading experience
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 0.5,
            alignItems: "center",
            mx: isNonMobileScreens ? 0 : 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, color: theme.palette.neutral.darkMain }}
          >
            {isNonMobileScreens && "Billed"} Monthly
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={isAnnual}
                  onChange={(e) => setIsAnnual(e.target.checked)}
                  size="small"
                />
              }
              sx={{ m: 0 }}
            />
          </FormGroup>
          <Typography
            variant="body1"
            sx={{ fontWeight: 600, color: theme.palette.neutral.darkMain }}
          >
            {isNonMobileScreens && "Billed"} Annually
          </Typography>{" "}
        </Box>
        <Typography variant="body1" color="primary" sx={{ fontWeight: 300 }}>
          (2 months free yearly for annual billing)
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems="center" justifyContent="center">
        {filteredPlans.map((plan) => (
          <Grid
            item
            key={plan.planName + plan.duration}
            xs={12}
            sm={plan.planName === "Basic" ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                gap: 4,
                border: plan.planName === "Premium" ? "1px solid" : undefined,
                borderColor:
                  plan.planName === "Premium" ? "primary.main" : undefined,
                background:
                  plan.planName === "Premium"
                    ? "linear-gradient(#188460, #2BA581, #62C99F)"
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    color: plan.planName === "Premium" ? "grey.50" : undefined,
                  }}
                >
                  <Typography component="h3" variant="h2">
                    ${plan.price}
                  </Typography>
                  <Typography component="h3" variant="h6">
                    &nbsp; {plan.duration === "year" ? "per year" : "per month"}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    color:
                      plan.planName === "Premium" ? "primary.light" : undefined,
                  }}
                >
                  {plan.billed}
                </Typography>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: "grey.500",
                  }}
                />
                {plan.features.map((feature) => (
                  <Box
                    key={feature}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          plan.planName === "Premium"
                            ? "primary.light"
                            : "primary.main",
                      }}
                    />
                    <Typography
                      component="span"
                      variant="body2"
                      sx={{
                        color:
                          plan.planName === "Premium" ? "grey.200" : undefined,
                      }}
                    >
                      {feature}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={plan.buttonVariant}
                  onClick={() => {
                    if (userProfile.currentPlan === plan.plan) {
                      if (plan.plan !== "free" && isSubscriptionActive()) {
                        handleCancelSubscription();
                      }
                    } else {
                      if (plan.plan === "free") {
                        handleCancelSubscription();
                      } else {
                        handleSubscribe(plan.link, userProfile.email);
                      }
                    }
                  }}
                  disabled={isButtonDisabled(plan)}
                  sx={
                    plan.planName === "Premium"
                      ? {
                          color: theme.palette.primary.extraLight,
                          background: theme.palette.primary.darkMain,
                          backgroundImage: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                          boxShadow: `inset 0 1px ${alpha(
                            theme.palette.primary.mediumMain,
                            0.4
                          )}`,
                          outline: `1px solid ${theme.palette.primary.lighterExtraDark}`,
                          "&:hover": {
                            background: theme.palette.primary.main,
                            backgroundImage: "none",
                            boxShadow: `0 0 0 1px  ${alpha(
                              theme.palette.primary.mediumMain,
                              0.5
                            )}`,
                          },
                          "&:disabled": {
                            color: theme.palette.primary.extraLight,
                            opacity: 0.7,
                          },
                        }
                      : {
                          "&:disabled": {
                            color: theme.palette.text.primary,
                            opacity: 0.7,
                          },
                        }
                  }
                >
                  {getButtonText(plan)}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      {/* <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Thank you for choosing " +
            (selectedPlan === "premium" ? "Premium" : "Pro") +
            " plan!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            We appreciate your interest in our{" "}
            {selectedPlan === "premium" ? "Premium" : "Pro"} plan. As we are
            currently in beta, we're offering{" "}
            <strong>free access to all features.</strong>
            We'll notify you when the subscription officially starts. In the
            meantime, enjoy all the benefits of ReadersGuild!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} autoFocus>
            Continue to Sign Up
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* New Dialog for cancellation feedback */}
      {/* <Dialog
        open={openCancelDialog}
        onClose={handleCloseCancelDialog}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">
          {"We're sorry to see you go!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            We'd appreciate your feedback to help us improve our service. Why
            are you cancelling your subscription?
          </DialogContentText>
          <RadioGroup
            aria-label="cancel-reason"
            name="cancel-reason"
            value={cancelReason}
            onChange={handleCancelReasonChange}
          >
            <FormControlLabel
              value="Too expensive"
              control={<Radio />}
              label="Too expensive"
            />
            <FormControlLabel
              value="Not enough features"
              control={<Radio />}
              label="Not enough features"
            />
            <FormControlLabel
              value="Found a better alternative"
              control={<Radio />}
              label="Found a better alternative"
            />
            <FormControlLabel
              value="Not using it enough"
              control={<Radio />}
              label="Not using it enough"
            />
            <FormControlLabel value="Other" control={<Radio />} label="Other" />
          </RadioGroup>
          {cancelReason === "Other" && (
            <TextField
              margin="dense"
              id="other-reason"
              label="Please specify"
              type="text"
              fullWidth
              variant="outlined"
              value={otherReason}
              onChange={handleOtherReasonChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog}>Keep Subscription</Button>
          <Button onClick={handleConfirmCancellation} autoFocus>
            Confirm Cancellation
          </Button>
        </DialogActions>
      </Dialog> */}
      {isSubscriptionBeingCanceled() && (
        <Typography variant="body2" sx={{ mt: 2, textAlign: "center" }}>
          Your subscription is cancelled. Your can still enjoy your premium
          access will until{" "}
          <strong>{formatDate(userProfile.subscriptionEndDate)}</strong>.
        </Typography>
      )}
    </Container>
  );
}
