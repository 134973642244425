import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

export const getBooks = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/books/index`);
    return response.data;
  } catch (error) {
    console.error("Error fetching books data:", error);
    throw error;
  }
};

export const getBooksByGoogleAPI = async (searchQuery) => {
  try {
    const response = axios.get(`${BASE_URL}/books/api/google`, {
      params: { searchQuery },
    });
    return response;
  } catch (error) {
    console.error("Error fetching books data from Google API:", error);
    throw error;
  }
};

export const getBook = async (bookId) => {
  try {
    const response = await axios.get(`${BASE_URL}/books/` + bookId);
    return response.data;
  } catch (error) {
    console.error("Error fetching book data:", error);
    throw error;
  }
};

export const removeBookFromUserCollection = async (bookId) => {
  try {
    const token = Cookies.get("x-auth-cookie"); // Get the token from cookie
    const response = await axios({
      url: `${BASE_URL}/books/` + bookId,
      method: "PATCH",
      withCredentials: "true",
      headers: {
        Authorization: `Bearer ${token}`, // Include token in Authorization header
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error removing book from user collection:", error);
    throw error;
  }
};

export const updateBookMetadata = async (bookId, bookData) => {
  try {
    const token = Cookies.get("x-auth-cookie");
    const response = await axios({
      url: `${BASE_URL}/books/${bookId}/metadata`,
      method: "PUT",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify(bookData),
    });
    return response.data;
  } catch (error) {
    console.error("Error updating book metadata:", error);
    throw error;
  }
};

// export const postLog = async (values, bookId) => {
//   const {
//     postDate,
//     lastPageRead,
//     readingNotes,
//     privateSwitch,
//     pageNumberOrPercentage,
//   } = values;
//   const response = await axios({
//     url: `${BASE_URL}/logs`,
//     // url: `${BASE_URL}/logs/` + bookId,
//     method: "POST",
//     withCredentials: true,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//     data: JSON.stringify({
//       postDate,
//       lastPageRead,
//       readingNotes,
//       privateSwitch,
//       pageNumberOrPercentage,
//     }),
//   });
//   return response;
// };
