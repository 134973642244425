import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3001";

// export const loginUser = async (values) => {
//   try {
//     const { username, password } = values;
//     const response = await axios({
//       url: `${BASE_URL}/login`,
//       method: "POST",
//       withCredentials: true,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//       data: JSON.stringify({ username, password }),
//     });
//     const { token } = response.data;
// Cookies.set("x-auth-cookie", token, { expires: 0.5 }); //     return response;
//   } catch (error) {
//     console.error("Login Error:", error);
//     throw error;
//   }
// };

export const checkEmailExistence = async (value) => {
  try {
    const { email } = value;
    const response = await axios({
      url: `${BASE_URL}/checkEmail`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ email }),
    });
    return response;
  } catch (error) {
    console.error("Login Error:", error);
    throw error;
  }
};
export const magicLinkLogin = async (email, rememberMe) => {
  try {
    const response = await axios({
      url: `${BASE_URL}/magicLogin`,
      method: "POST",
      withCredentials: "true",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      data: JSON.stringify({ email, rememberMe }),
    });
    return response;
  } catch (error) {
    console.error("Login Error:", error);
    throw error;
  }
};

export const submitOnboardingDataAndRegister = async (data) => {
  try {
    const response = await axios.post(`${BASE_URL}/registerUser`, data, {
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    const { token } = response.data;
    Cookies.set("x-auth-cookie", token, { expires: 30 });
    Cookies.set("jwt", token, { expires: 30 });
    return response.data;
  } catch (error) {
    console.error("Submission Error:", error);
    throw error;
  }
};

export const verifyMagicLinkToken = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/verify?token=${token}`, {
      withCredentials: true,
      headers: {
        Accept: "application/json", // Ensure JSON response expected
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    if (response.data.success) {
      const { token, rememberMe } = response.data;
      const cookieExpiry = rememberMe ? 90 : 7; // Set cookie expiry to 90 days if rememberMe is true, otherwise 2 days
      Cookies.set("x-auth-cookie", token, { expires: cookieExpiry });
      Cookies.set("jwt", token, { expires: cookieExpiry });
    }
    return response; // Assuming the response contains the verification status or data
  } catch (error) {
    console.error("Verification Error:", error);
    throw error; // Propagate the error for handling in the caller function
  }
};

export const registerUser = async (formData) => {
  try {
    const response = await axios.post(`${BASE_URL}/register`, formData, {
      withCredentials: "true",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const { token } = response.data;
    Cookies.set("x-auth-cookie", token, { expires: 2 });
    Cookies.set("jwt", token, { expires: 2 });
    return response;
  } catch (error) {
    throw new Error(error.response.data.message || "Registration failed");
  }
};

// export const getTokenToEntry = async () => {
//   try {
//     const response = await axios.get(`${BASE_URL}/google/callback`, {
//       withCredentials: true,
//     });
//     const { token } = response.data;
//     console.log(token);
// Cookies.set("x-auth-cookie", token, { expires: 0.5 }); //     return response;
//     // After receiving the response, redirect the user to the Google OAuth2 authentication page
//     // window.location.href = response.data.redirectUrl;
//   } catch (error) {
//     console.error("Google Login Error:", error);
//     throw error;
//   }
// };

// export const registerUser = async (formData) => {
//   const response = await axios({
//     url: `${BASE_URL}/register`,
//     method: "POST",
//     withCredentials: true,
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     data: formData,
//   });
//   return response;
// };

// export const logoutUser = async () => {
//   const response = await axios({
//     url: `${BASE_URL}/logout`,
//     method: "GET",
//     withCredentials: true,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//   });
//   return response.data;
// };

export const logoutUser = async () => {
  try {
    Cookies.remove("x-auth-cookie"); // Remove token from cookie
    Cookies.remove("jwt"); // Remove token from cookie
    localStorage.removeItem("userData");
    const response = await axios.get(`${BASE_URL}/logout`);
    return response.data;
  } catch (error) {
    console.error("Logout Error:", error);
    throw error;
  }
};

// export const checkAuthentication = async () => {
//   const response = await axios({
//     url: `${BASE_URL}/checkAuth`,
//     method: "GET",
//     withCredentials: true,
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//   });
//   return response;
// };

/* FORCE LOGOUT */

// export const checkTokenValidity = async () => {
//   try {
//     await axios.get(`${BASE_URL}/checkAuth`, {
//       withCredentials: true,
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json;charset=UTF-8",
//       },
//     });
//   } catch (error) {
//     if (error.response && error.response.status === 401) {
//       Cookies.remove("x-auth-cookie");
//       localStorage.removeItem("userData");
//       window.location.href = "/";
//     }
//   }
// };
