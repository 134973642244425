import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  useTheme,
  Typography,
  Box,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { magicLinkLogin } from "../../../api/authApi";
import { useSocket } from "../../../context/SocketContext";
import { useAuth } from "../../../context/AuthContext";
import Cookies from "js-cookie";

const SignUpForm = () => {
  const [email, setEmail] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isWaitingForVerification, setIsWaitingForVerification] =
    useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const socket = useSocket();
  const { checkLogin } = useAuth();

  useEffect(() => {
    if (socket && isWaitingForVerification) {
      const handleUserLoggedIn = ({ userId, token }) => {
        Cookies.set("x-auth-cookie", token, { expires: rememberMe ? 30 : 1 });
        checkLogin();
        navigate("/home");
      };

      socket.on("userLoggedIn", handleUserLoggedIn);

      return () => {
        socket.off("userLoggedIn", handleUserLoggedIn);
      };
    }
  }, [socket, isWaitingForVerification, checkLogin, navigate, rememberMe]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleRememberMeChange = (e) => {
    setRememberMe(e.target.checked);
  };

  const handlePostEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const result = await magicLinkLogin(email, rememberMe);
      if (result.data.success) {
        setSuccessMessage(
          "Verification link sent to your email! Check your inbox (and spam folder, just in case)."
        );
        setIsWaitingForVerification(true);
      } else {
        console.error(result.data.message);
      }
    } catch (error) {
      console.error("Login Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "600px",
        gap: 1.5,
      }}
    >
      <TextField
        label="Type your email..."
        value={email}
        size="small"
        type="email"
        name="email"
        onChange={handleEmailChange}
        disabled={isLoading || isWaitingForVerification}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={handleRememberMeChange}
            color="primary"
            disabled={isLoading || isWaitingForVerification}
          />
        }
        label="Remember this device"
      />
      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={handlePostEmail}
        disabled={isLoading || isWaitingForVerification}
      >
        {isLoading
          ? "Sending..."
          : isWaitingForVerification
          ? "Waiting for verification..."
          : "Continue"}
      </Button>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          variant="body2"
          sx={{ fontSize: "12px", color: theme.palette.neutral.main }}
        >
          {isWaitingForVerification
            ? "Please verify your email to continue."
            : "If you already have an account, we'll log you in"}
        </Typography>
      </Box>

      {(isLoading || isWaitingForVerification) && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {successMessage && (
        <Box fullWidth>
          <Box
            fullWidth
            sx={{
              mt: 2,
              display: "flex",
              alignItems: "center",

              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                fontWeight: "500",
                color: "neutral",
              }}
            >
              {successMessage}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SignUpForm;
