import { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import parse from "html-react-parser";
import DialogEditNote from "../../components/DialogEditNote";

// const defaultAvatar = "/path/to/default/avatar.png";
const defaultCover = "/assets/book-sample.webp";

const CommunityCard = ({
  log,
  currentUserId,
  onDelete,
  onUpdateNote,
  isMobileScreen,
  isSmallScreen,
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [currentNotes, setCurrentNotes] = useState(log.readingNotes || "");
  const isSmallerScreen = useMediaQuery("(max-width:1400px)");
  const [showFullNotes, setShowFullNotes] = useState(false);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteLog = async (logId) => {
    try {
      console.log("check remove log id", logId);
      await onDelete(logId);
      handleClose();
    } catch (error) {
      console.error("Error removing reading log:", error);
      handleClose();
    }
  };

  const editNote = async (editedNote, privateSwitch) => {
    try {
      await onUpdateNote(log._id, editedNote, privateSwitch);
      setCurrentNotes(editedNote);
      handleClose();
    } catch (error) {
      console.error("Error updating post:", error);
      handleClose();
    }
  };

  const truncateNotes = (notes) => {
    const maxLength = 330;
    if (notes.length <= maxLength) return notes;
    return notes.slice(0, maxLength) + "...";
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        boxShadow: 2,
        p: 2,
        gap: 1.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "background.paper",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            mb: isSmallScreen ? 1 : 0,
          }}
        >
          <Avatar
            src={log.user?.avatar}
            alt={log.user?.name || "Anonymous User"}
            sx={{ fontSize: "15px", width: 40, height: 40, mr: 2 }}
          />
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{ fontSize: "15px", mr: 1 }}
          >
            {log.user?.name || "Anonymous User"}
          </Typography>
          <Typography
            sx={{
              fontSize: "15px",
              color: theme.palette.neutral.medium,
              mr: 1,
            }}
          >
            •
          </Typography>
          <Typography
            sx={{ fontSize: "15px", color: theme.palette.neutral.medium }}
          >
            {new Date(log.postDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mt: isSmallScreen ? 1 : 0,
          }}
        >
          {currentUserId === log.user?._id && (
            <IconButton onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1.5,
          px: isSmallScreen ? 0.5 : 0,
        }}
      >
        <Tooltip
          title={`From "${log.book?.title || "Untitled Book"}" by "${
            log.book?.authors?.join(", ") || "Unknown Author"
          }"`}
          arrow
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              //   fontSize: "15px",
              color: theme.palette.neutral.medium,
              maxWidth: { xs: "100%" },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            From "{log.book?.title || "Untitled Book"}" by "
            {log.book?.authors?.join(", ") || "Unknown Author"}"
          </Typography>
        </Tooltip>
        <Typography
          sx={{
            fontSize: "15px",
            color: theme.palette.neutral.medium,
          }}
        >
          •
        </Typography>
        <Typography
          variant="body2"
          sx={{
            fontWeight: "400",
            //   fontSize: "15px",
            color: theme.palette.neutral.medium,
            maxWidth: { xs: "100%" },
          }}
        >
          Pg. {log.lastPageRead}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        {!isSmallScreen && (
          <CardMedia
            component="img"
            sx={{
              width: 100,
              height: 150,
              objectFit: "cover",
              mr: 2,
              mb: 0,
            }}
            image={
              log.book?.cover?.large ||
              log.book?.cover?.medium ||
              log.book?.cover?.small ||
              log.book?.cover ||
              defaultCover
            }
            alt={log.book?.title || "Book cover"}
          />
        )}
        <Box sx={{ flex: 1, p: 0 }}>
          <Box
            sx={{
              backgroundColor: theme.palette.background.default,
              borderRadius: 1,
              py: 1,
              px: 2,
              position: "relative",
            }}
          >
            {log.readingNotes ? (
              <>
                <Typography
                  variant="body2"
                  sx={{ "& p": { marginBottom: "6px", marginTop: "6px" } }}
                >
                  <span>
                    {showFullNotes
                      ? parse(currentNotes)
                      : parse(truncateNotes(currentNotes))}

                    {/* Conditional rendering of the "More" button */}
                    {log.readingNotes.length > 200 && !showFullNotes && (
                      <Typography
                        component="span"
                        variant="subtitle1"
                        sx={{
                          color: theme.palette.primary.main,
                          cursor: "pointer",
                          "&:hover": { textDecoration: "underline" },
                          display: "inline",
                        }}
                        onClick={() => setShowFullNotes(true)}
                      >
                        more
                      </Typography>
                    )}
                  </span>
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No notes available.
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => setIsEditDialogOpen(true)}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Edit
        </MenuItem>
        <MenuItem onClick={() => deleteLog(log._id)}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Remove
        </MenuItem>
      </Menu>
      <DialogEditNote
        open={isEditDialogOpen}
        onClose={() => setIsEditDialogOpen(false)}
        onSave={editNote}
        switchData={log.privateSwitch}
        initialNote={log.readingNotes}
        bookTitle={log.book?.title}
        logId={log._id}
      />
    </Card>
  );
};

export default CommunityCard;
